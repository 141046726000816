import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import {
  Autocomplete, Dialog, IconButton, TextField,
} from '@mui/material';
import { Clear } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { v4 as uuid } from 'uuid';
import { GlideApp } from '../glide-to-bq/types';
import {
  BQDataset,
  BQDatasetTable,
  BQProject, BQToGlideJob, CreateBQToGlideJobPayload, EditBQToGlideJobPayload,
} from './types';
import {
  createBQToGlideJob, editBQToGlideJob, getBQPreview, getBQTables,
} from '../../../../api/endpoints-api/EndpointsAPI';
import { useAlert } from '../../../../api/alert-api/AlertAPI';
import { PreviewRows } from '../../google-sheet-sync/manage-job-modal/PreviewRows';
import { SyncFrequencyOptions } from '../../google-sheet-sync/manage-job-modal/SyncFrequencyOptions';
import { FrequencyOptions, TableGroupDoc, TableGroupMapping } from '../../landing-page-types';
import {
  calculateSyncCron,
  defaultDayOfMonth,
  defaultDayOfWeek,
  defaultFrequency,
  getFrequencyFields, decodeFrequencyDetails,
} from '../../../../api/landing-page-api/LandingPageAPI';
import { jobNameInConflict } from '../helpers';
import { useUser } from '../../../../api';
import { DependentGroupSelector } from '../../common/DependentGroupSelector';
import { updateTableGroup } from '../../common/helpers';

// Params for ManageBQToGlideJobModal
interface ManageBQToGlideJobModalParams {
  modalOpen: boolean,
  closeModal: ()=> void
  jobs: BQToGlideJob[]
  glideApps: GlideApp[]
  jobBeingEdited?: BQToGlideJob
  projects: BQProject[]
  groups: TableGroupDoc[]
  groupMappings: TableGroupMapping[]
}

/**
 * Generate the initial form values for the modal (based on whether the job is being edited or created).
 * @param jobBeingEdited job being edited (if there is one - if not, job is being created)
 * @param projects all BQ projects
 * @param groupMappings job group mappings
 */
const generateInitValues = (
  jobBeingEdited: BQToGlideJob | undefined,
  projects: BQProject[],
  groupMappings: TableGroupMapping[],
) => {
  if (jobBeingEdited) {
    // get frequency values
    const { displaySyncFreqType, displaySyncFreqDetail } = jobBeingEdited;
    const freqValues = decodeFrequencyDetails(displaySyncFreqDetail, displaySyncFreqType);

    // unpack project/dataset values from full table ID
    const [project, dataset, table] = jobBeingEdited.tableId.split('.');
    const selectedProject = projects.find(({ projectName }) => projectName === project);
    if (!selectedProject) throw new Error('Unable to find project');

    // get job group (if there is one)
    const selectedGroup = groupMappings.find((mapping) => mapping.tableId === jobBeingEdited.jobId)?.groupId || '';

    return {
      selectedProject,
      selectedBQDataset: dataset,
      selectedBQTable: { id: table, disabled: false },
      selectedFrequency: jobBeingEdited.displaySyncFreqType,
      selectedDayOfWeek: freqValues.selectedDayOfWeek,
      selectedDayOfMonth: freqValues.selectedDayOfMonth,
      glideTableName: jobBeingEdited.glideTableName,
      comments: jobBeingEdited.comments,
      jobName: jobBeingEdited.jobName,
      selectedGroup,
    };
  }
  // if new job, return default values
  return {
    selectedProject: null,
    selectedBQDataset: '',
    selectedBQTable: null,
    selectedFrequency: defaultFrequency,
    selectedDayOfWeek: defaultDayOfWeek,
    selectedDayOfMonth: defaultDayOfMonth,
    glideTableName: '',
    comments: '',
    jobName: '',
    selectedGroup: '',
  };
};

/**
 * Create or edit a BQ To Glide job.
 * @param modalOpen whether the modal is open
 * @param closeModal function to close modal
 * @param jobs all jobs
 * @param glideApps all glide apps
 * @param jobBeingEdited the job being edited
 * @param projects all BigQuery projects
 * @param groups job groups
 * @param groupMappings job group mappings
 * @constructor
 */
export const ManageBQToGlideJobModal = function ({
  modalOpen, closeModal, jobs, glideApps, jobBeingEdited, projects, groups, groupMappings,
}: ManageBQToGlideJobModalParams) {
  // get initial values of the modal form - this is based on whether a job is being created or edited
  const initValues = generateInitValues(jobBeingEdited, projects, groupMappings);

  // form/job values
  const [selectedProject, setSelectedProject] = useState<BQProject | null>(initValues.selectedProject);
  const [selectedBQDataset, setSelectedBQDataset] = useState<string>(initValues.selectedBQDataset);
  const [selectedBQTable, setSelectedBQTable] = useState<BQDatasetTable | null>(initValues.selectedBQTable);
  const [glideTableName, setGlideTableName] = useState<string>(initValues.glideTableName);
  const [comments, setComments] = useState<string>(initValues.comments);
  const [selectedDayOfWeek, setSelectedDayOfWeek] = useState<number>(initValues.selectedDayOfWeek);
  const [selectedDayOfMonth, setSelectedDayOfMonth] = useState<number>(initValues.selectedDayOfMonth);
  const [selectedFrequency, setSelectedFrequency] = useState<FrequencyOptions>(initValues.selectedFrequency);
  const [jobName, setJobName] = useState<string>(initValues.jobName);
  const [selectedGroup, setSelectedGroup] = useState<string>(initValues.selectedGroup);

  const [previewRows, setPreviewRows] = useState<Record<string, any>[]>([]);
  const [bqDatasets, setBQDatasets] = useState<BQDataset[]>([]);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [loadingPreview, setLoadingPreview] = useState<boolean>(false);
  const [loadingTables, setLoadingTables] = useState<boolean>(false);

  const updateSelectedGroup = (group: string) => setSelectedGroup(group);

  const alert = useAlert();
  const user = useUser();

  /**
   * Given a GCP project, get all BQ tables/datasets from the project.
   */
  useEffect(() => {
    if (!selectedProject) return;
    setLoadingTables(true);
    getBQTables(selectedProject.projectId)
      .then((res: AxiosResponse<BQDataset[]>) => setBQDatasets(res.data))
      .catch(() => alert?.alert('Failed to retrieve BigQuery tables', 'error'))
      .finally(() => setLoadingTables(false));
  }, [selectedProject]);

  /**
   * Get preview rows from BigQuery table.
   */
  useEffect(() => {
    if (!selectedProject || !selectedBQTable || !selectedBQDataset) return;
    const payload = {
      projectId: selectedProject.projectId,
      datasetId: selectedBQDataset,
      tableId: selectedBQTable.id,
    };
    setLoadingPreview(true);
    getBQPreview(payload)
      .then((res: AxiosResponse<Record<string, any>[]>) => setPreviewRows(res.data))
      .catch(() => alert?.alert('Failed to retrieve preview', 'error'))
      .finally(() => setLoadingPreview(false));
  }, [selectedBQTable]);

  // encoded frequency values for updating/creating job
  const {
    displaySyncFreqType, displaySyncFreqDetail,
  } = getFrequencyFields(selectedFrequency, selectedDayOfWeek, selectedDayOfMonth);

  // refresh cron expression calculated from frequency values
  const refreshCron = calculateSyncCron(selectedFrequency, selectedDayOfMonth, selectedDayOfWeek);

  // if the app is changed, null out the selected table
  useEffect(() => {
    // this causes a bug if we leave it on in the Edit modal, so we only do this in the Create modal
    if (!jobBeingEdited) setSelectedBQDataset('');
  }, [selectedProject]);

  useEffect(() => {
    if (!jobBeingEdited) setSelectedBQTable(null);
  }, [selectedBQDataset]);

  /**
   * Create the given job.
   */
  const createJob = () => {
    if (!user?.email || !selectedProject || !selectedBQDataset || !selectedBQTable) return;
    setLoadingSubmit(true);
    const jobId = uuid();
    const payload: CreateBQToGlideJobPayload = {
      jobId,
      jobName,
      comments,
      displaySyncFreqType,
      displaySyncFreqDetail,
      refreshCron,
      userEmail: user.email,
      project: selectedProject.projectId,
      dataset: selectedBQDataset,
      table: selectedBQTable.id,
      glideTableName,
    };
    createBQToGlideJob(payload)
      .then(async () => {
        closeModal();
        alert?.alert('Successfully created job', 'success');
        await updateTableGroup(selectedGroup, jobId, groupMappings);
      })
      .catch(() => alert?.alert('Failed to create job', 'error'))
      .finally(() => setLoadingSubmit(false));
  };

  /**
   * Edit the given job.
   */
  const editJob = () => {
    if (!jobBeingEdited) return;
    setLoadingSubmit(true);
    const payload: EditBQToGlideJobPayload = {
      jobId: jobBeingEdited.jobId,
      jobName,
      comments,
      refreshCron,
      displaySyncFreqDetail,
      displaySyncFreqType,
    };
    editBQToGlideJob(payload)
      .then(async () => {
        alert?.alert('Successfully edited job', 'success');
        closeModal();
        await updateTableGroup(selectedGroup, jobBeingEdited.jobId, groupMappings);
      })
      .catch(() => alert?.alert('Failed to edit job', 'error'))
      .finally(() => setLoadingSubmit(false));
  };

  // whether the glide table name input by the user conflicts with an existing Glide table name
  const allGlideTableNames = glideApps.flatMap(({ tables }) => tables.map(({ name }) => name));
  const glideTableNameConflict = allGlideTableNames.includes(glideTableName) && !jobBeingEdited;

  // whether the user-inputted job name conflicts with another job name
  const jobNameConflict = jobNameInConflict(jobName, jobs, jobBeingEdited?.jobName);

  const missingValues = !jobName || !selectedProject || !selectedBQDataset || !selectedBQTable || !glideTableName;
  const disableSubmit = jobNameConflict || glideTableNameConflict || missingValues;

  // all tables in selected BQ dataset
  const tablesInDataset = bqDatasets.find((dataset) => dataset.dataset === selectedBQDataset)?.tables || [];

  return (
    <Dialog
      open={modalOpen}
      onClose={closeModal}
      className="w-[100vw] bg-transparent"
      maxWidth="xl"
    >
      <div className="relative z-40 w-[80vw] h-[90vh] bg-white rounded-lg flex flex-col items-center">
        {/* Close modal button */}
        <div className="flex flex-row w-full justify-end">
          <IconButton onClick={closeModal}>
            <Clear />
          </IconButton>
        </div>
        {/* Modal header */}
        <div className="mb-10 text-xl font-medium">{jobBeingEdited ? 'Edit Job' : 'Add New Job'}</div>
        <div className="flex flex-row justify-evenly w-full">
          <div className="flex flex-col w-1/3 gap-y-10">
            {/* GCP Project selector */}
            <Autocomplete
              options={projects}
              value={selectedProject}
              onChange={(e, value) => setSelectedProject(value)}
              getOptionLabel={({ projectName }) => projectName}
              renderInput={(params) => <TextField {...params} label="GCP Project" />}
              disabled={!!jobBeingEdited}
            />
            {/* BQ Dataset selector */}
            <Autocomplete
              options={loadingTables ? [] : bqDatasets.map(({ dataset }) => dataset)}
              value={selectedBQDataset}
              onChange={(e, value) => setSelectedBQDataset(value || '')}
              getOptionLabel={(option) => option}
              renderInput={(params) => <TextField {...params} label="Dataset" />}
              disabled={!!jobBeingEdited}
              loading={loadingTables}
            />
            {/* BQ Table selector */}
            <Autocomplete
              options={tablesInDataset}
              value={selectedBQTable}
              onChange={(e, value) => setSelectedBQTable(value || null)}
              getOptionLabel={({ id }) => id}
              getOptionDisabled={({ disabled }) => disabled}
              renderInput={(params) => <TextField {...params} label="BQ Table" />}
              disabled={!!jobBeingEdited}
            />
            {/* Job name input */}
            <TextField
              label="Job Name"
              variant="outlined"
              value={jobName}
              helperText={jobNameConflict && <div className="text-red-500">Already exists a job with that name</div>}
              onChange={(e) => setJobName(e.target.value)}
            />
            {/* Job group selector */}
            <DependentGroupSelector
              groups={groups}
              selectedGroup={selectedGroup}
              setSelectedGroup={updateSelectedGroup}
            />
            {/* Glide table name input */}
            <TextField
              label="Glide table name"
              variant="outlined"
              value={glideTableName}
              helperText={
                    glideTableNameConflict
                    && <div className="text-red-500">Already exists a Glide table with that name in that app</div>
                }
              onChange={(e) => {
                // validate that table name passes regex validation
                const { value } = e.target;
                const glideTableRegex = /^[A-Za-z0-9_\s.-]*$/;
                if (glideTableRegex.test(value)) setGlideTableName(value);
              }}
              disabled={!!jobBeingEdited}
            />
          </div>
          <div className="flex flex-col w-1/3 gap-y-2">
            {/* BQ rows preview */}
            <PreviewRows
              rows={previewRows}
              loadingSheetData={loadingPreview}
              userDoesNotHaveSheetAccess={false}
            />
            {/* Sync frequency selector */}
            <SyncFrequencyOptions
              selectedDayOfWeek={selectedDayOfWeek}
              selectedDayOfMonth={selectedDayOfMonth}
              selectedFrequency={selectedFrequency}
              updateSelectedDayOfMonth={setSelectedDayOfMonth}
              updateSelectedDayOfWeek={setSelectedDayOfWeek}
              updateSelectedFrequency={setSelectedFrequency}
              loadingSheetData={false}
            />
            {/* Comments input */}
            <TextField
              label="Comments"
              multiline
              rows={5}
              variant="outlined"
              value={comments}
              onChange={({ target }) => setComments(target.value)}
            />
            {/* Submit button */}
            <LoadingButton
              variant="contained"
              className="bg-blue-500"
              loading={loadingSubmit}
              onClick={jobBeingEdited ? editJob : createJob}
              disabled={disableSubmit}
            >
              Submit job
            </LoadingButton>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
