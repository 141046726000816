import { IconButton, Tooltip } from '@mui/material';
import { Delete } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { TableGroupActionsParams } from './params';
import { db } from '../../../../firebase-config';
import { groupMappingsPath, TableGroupMapping } from '../landing-page-types';
import { useAlert } from '../../../api/alert-api/AlertAPI';

// id used for the Unsorted group (contains all ungrouped jobs)
export const UNSORTED_GROUP_ID = 'unsorted';

/**
 * Some groups have invisible members i.e. members which are not shown in the current DataGrid/view.
 * Most data sources (Entrata, Google Sheets) only have one DataGrid per data source in which we can view jobs.
 * Glide, for instance, has two DataGrids which share groups.  As such, if a group has members in one DataGrid but not the other,
 * it will appear to have zero members in the latter DataGrid.  We need to know if a group has these invisible members
 * so that we can change the UI and prevent the group's inadvertent deletion.
 * @param groupId id of the group to check
 */
const getDisableDelete = async (groupId: string) => {
  // get ALL mappings (members) of this group
  const mappingsRes = await getDocs(collection(db, groupMappingsPath));
  const mappingsDocs = mappingsRes.docs.map((groupDoc) => groupDoc.data()) as TableGroupMapping[];
  // return true if the groups has any members at all
  return !!mappingsDocs.filter((doc) => doc.groupId === groupId).length;
};

/**
 * Actions for table group.
 * @param group the group we are acting on
 * @param updateGroupToDelete opens the Delete Group Confirmation modal for a given group
 * @constructor
 */
export const TableGroupActions = function ({ group, updateGroupToDelete }: TableGroupActionsParams) {
  const [disableDelete, setDisableDelete] = useState<boolean>(false);

  const alert = useAlert();

  /**
   * Check whether the group has members not visible in the current view/DataGrid.
   */
  useEffect(() => {
    getDisableDelete(group.groupId)
      .then((res) => setDisableDelete(res))
      .catch(() => alert?.alert('Failed to retrieve group mappings', 'error'));
  }, []);

  // don't allow users to delete groups with tables still in them
  if (group.tables.length || group.groupId === UNSORTED_GROUP_ID) return null;

  const tooltipText = disableDelete ? 'This group cannot be deleted as it has members not shown in this view' : '';

  return (
    <Tooltip title={tooltipText}>
      <div>
        <IconButton
          onClick={() => updateGroupToDelete(group)}
          disabled={disableDelete}
        >
          <Delete />
        </IconButton>
      </div>
    </Tooltip>
  );
};
