import { Skeleton, TextField } from '@mui/material';
import React from 'react';
import { TableNameInputParams } from '../../landing-page-params';
import { ApiErrorCode } from '../../landing-page-types';

// Regex to validate BQ table name
export const bqTableNameRegex = /^[A-Za-z0-9_-]*$/;

export const TableNameInput = function ({
  syncJob, updateSyncJob, existingSyncJob, apiError, loadingSheetData,
}: TableNameInputParams) {
  if (loadingSheetData) return <Skeleton variant="rectangular" className="w-full h-16 mt-8" />;
  const { tableName } = syncJob;

  // if apiError's error code is relevant to the tableName/this component, then display apiError's message
  const tableNameErrorMessage = apiError?.errorCode === ApiErrorCode.TABLE_ALREADY_EXISTS ? apiError.message : '';

  // Only updates table name if the string is a valid table name and does not contain forbidden chars.
  const parseTableName = ({ target }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (bqTableNameRegex.test(target.value)) updateSyncJob({ ...syncJob, tableName: target.value });
  };

  return (
    <TextField
      label="BigQuery table name"
      variant="outlined"
      className="mt-8"
      value={tableName}
      onChange={parseTableName}
      error={!!tableNameErrorMessage}
      helperText={tableNameErrorMessage}
      disabled={!!existingSyncJob}
    />
  );
};
