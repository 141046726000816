import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { Box, IconButton, Skeleton } from '@mui/material';
import React from 'react';
import { Delete, Edit, OpenInNew } from '@mui/icons-material';
import {
  JobRunHistory, SheetSyncJob, WriteDisposition, UpdateGroupInfo,
} from '../../landing-page-types';
import { JobsDataGridParams } from '../../landing-page-params';
import { getLastRunTimestamp, getShortenedTableId } from '../../../../api/landing-page-api/LandingPageAPI';
import { StandaloneGroupSelector } from '../../common/StandaloneGroupSelector';
import { generateBigQueryURL, getSyncSchedule } from '../../common/helpers';
import { RunStatusChip } from '../../common/RunStatusChip';
import { ManualSyncButton } from '../../common/ManualSyncButton';

/**
 * Core columns for Sheets datagrid.  These are also used in the Deletion modal.
 */
export const coreColumns: GridColDef<SheetSyncJob>[] = [
  {
    field: 'jobName',
    headerName: 'Job Name',
    flex: 1,
  },
  {
    field: 'sheetName',
    headerName: 'Sheet Name',
    flex: 1,
    renderCell: ({ row }) => (
      <a
        href={row.workbookUrl}
        target="_blank"
        className="text-blue-500 underline"
        rel="noreferrer"
      >
        {row.sheetName}
      </a>
    ),
  },
  {
    field: 'tableId',
    headerName: 'Table ID',
    flex: 1,
    renderCell: ({ row }) => {
      const shortId = getShortenedTableId(row.tableId);
      const [project, dataset, table] = row.tableId.split('.');
      const url = generateBigQueryURL(project, dataset, table);
      return (
        <a
          href={url}
          target="_blank"
          className="text-blue-500 underline"
          rel="noreferrer"
        >
          {shortId}
        </a>
      );
    },
  },
  {
    field: 'refreshCron',
    headerName: 'Sync Schedule',
    flex: 1,
    // displays cron as a more readable, descriptive string
    valueGetter: ({ row }) => getSyncSchedule(row.refreshCron),
  },
  {
    field: 'writeMethod',
    headerName: 'Upload Method',
    flex: 1,
    // gets more readable version of write disposition
    valueGetter: ({ row }) => (row.writeMethod === WriteDisposition.APPEND ? 'Append' : 'Overwrite'),
  },
  {
    field: 'uuidColumn',
    headerName: 'ID Column',
    flex: 1,
  },
  {
    field: 'owner',
    headerName: 'Owner',
    flex: 1,
    // if a job is in the table and not shared, it is owned by the current user
    valueGetter: ({ row }) => (row.shared ? 'Shared' : 'You'),
  },

];

/**
 * Generate columns for jobs datagrid.
 * @param updateDeleteModalJobId updates the delete job modal status
 * @param updateEditModalJobId update the edit job modal status
 * @param interactive whether to show interactive actions on the datagrid
 * @param jobRunHistory job run history for all jobs
 * @param updateGroupInfo data which allows user to update the group of a given job (this is conditionally passed)
 */
export const generateColumns = (
  updateDeleteModalJobId: (id: string) => void,
  updateEditModalJobId: (id: string) => void,
  interactive: boolean,
  jobRunHistory: JobRunHistory[],
  updateGroupInfo: UpdateGroupInfo | undefined,
): GridColDef<SheetSyncJob>[] => {
  const interactiveColumns: GridColDef<SheetSyncJob>[] = updateGroupInfo ? [
    {
      field: 'lastRunStatus',
      headerName: 'Last Run Status',
      flex: 1,
      // if a job is in the table and not shared, it is owned by the current user
      renderCell: ({ row }) => <RunStatusChip jobId={row.jobId} jobRunHistory={jobRunHistory} />,
    },
    {
      field: 'lastRunTimestamp',
      headerName: 'Last Run Time',
      flex: 1,
      // if a job is in the table and not shared, it is owned by the current user
      valueGetter: ({ row }) => getLastRunTimestamp(jobRunHistory, row.jobId),
    },
    {
      field: 'editJob',
      headerName: 'Edit',
      headerAlign: 'center',
      flex: 0.5,
      // opens edit job modal (manageJobModal} in edit mode)
      renderCell: ({ row }) => (
        <IconButton
          className="mx-auto"
          onClick={() => updateEditModalJobId(row.jobId)}
        >
          <Edit />
        </IconButton>
      ),
    },
    {
      field: 'deleteJob',
      headerName: 'Delete',
      headerAlign: 'center',
      flex: 0.5,
      // opens delete job modal
      renderCell: ({ row }) => (
        <IconButton
          className="mx-auto"
          onClick={() => updateDeleteModalJobId(row.jobId)}
        >
          <Delete />
        </IconButton>
      ),
    },
    {
      field: 'manualSync',
      headerName: 'Sync',
      headerAlign: 'center',
      flex: 0.5,
      // manually runs a sync for the given job
      renderCell: ({ row }) => ManualSyncButton(row.jobId),
    },
    {
      headerName: 'Group',
      field: 'group',
      width: 150,
      renderCell: ({ row }) => (
        <StandaloneGroupSelector
          tableId={row.jobId}
          refreshTable={updateGroupInfo.refreshTable}
          groupMappings={updateGroupInfo.groupMappings}
          groups={updateGroupInfo.groups}
          currentGroupId={updateGroupInfo.currentGroupId}
        />
      ),
    },
  ] : [];
  return interactive ? [...coreColumns, ...interactiveColumns] : coreColumns;
};

/**
 * Shows Google Sheets sync jobs.
 * @param updateDeleteModalJobId updates the delete job modal status
 * @param updateEditModalJobId update the edit job modal status
 * @param jobRows the jobs in the datagrid
 * @param interactive whether to show interactive actions on the datagrid
 * @param jobsLoading whether the jobs are loading
 * @param jobRunHistory job run history for all jobs
 * @param updateGroupInfo data which allows user to update the group of a given job (this is conditionally passed)
 * @constructor
 */
export const JobsDataGrid = function ({
  updateDeleteModalJobId,
  updateEditModalJobId,
  jobRows,
  interactive,
  jobsLoading,
  jobRunHistory,
  updateGroupInfo,
}: JobsDataGridParams) {
  const columns = generateColumns(
    updateDeleteModalJobId,
    updateEditModalJobId,
    interactive,
    jobRunHistory,
    updateGroupInfo,
  );

  const containerStyle = { height: '100%', width: '100%' };

  if (jobsLoading) return <Skeleton sx={containerStyle} variant="rectangular" />;

  return (
    <Box sx={containerStyle}>
      <DataGridPro
        rows={jobRows}
        columns={columns}
        getRowId={({ jobId }: SheetSyncJob) => jobId}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        disableRowSelectionOnClick
      />
    </Box>
  );
};
