import { Button, CircularProgress } from '@mui/material';
import React, { useMemo } from 'react';
import { areJobFieldsUnchanged } from '../../../../api/landing-page-api/LandingPageAPI';
import { SubmitButtonParams } from '../../landing-page-params';

export const SubmitButton = function ({
  existingSyncJob, loadingSubmit, syncJob, frequencyCron, jobNameIsNotUnique, handleSubmit, groupUnchanged,
}: SubmitButtonParams) {
  const {
    jobName, workbookUrl, tableName, selectedSheet,
  } = syncJob;

  // this represents whether any job fields have been changed/edited in the modal (if modal in edit mode)
  const jobFieldsUnchanged = useMemo(
    () => areJobFieldsUnchanged(syncJob, existingSyncJob, frequencyCron),
    [syncJob, existingSyncJob, frequencyCron],
  );

  const disableEdit = (jobFieldsUnchanged && groupUnchanged) || loadingSubmit || !jobName;
  const requiredFieldsEmpty = !selectedSheet || !tableName || !workbookUrl || !jobName;
  const disableCreate = loadingSubmit || jobNameIsNotUnique || requiredFieldsEmpty;

  const disableButton = existingSyncJob ? disableEdit : disableCreate;
  return (
    <Button
      className="bg-blue-500 text-white w-24 h-12 absolute bottom-8 right-8 hover:bg-blue-400 disabled:bg-blue-300
                 disabled:text-white"
      onClick={handleSubmit}
      disabled={disableButton}
    >
      {/* Shows loading icon while waiting for backend to create/edit job */}
      {loadingSubmit ? <CircularProgress size="2rem" /> : 'Submit'}
    </Button>
  );
};
