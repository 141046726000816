import React, { useEffect, useState } from 'react';
import {
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';
import { CenteredCircularProgress } from './components/helper';
import history from './components/api/history/History';
import errorAPI from './components/api/error-api/ErrorAPI';
import {
  ErrorPage, LandingPage, LoginPage, NotFoundPage,
} from './components/pages';
import { useUser } from './components/api';
import { AdminPage } from './components/pages/admin-page/AdminPage';
import { UserRole, UserRoles } from './components/pages/admin-page/admin-types';
import { DataSource } from './components/pages/landing-page/landing-page-types';

export const App = function () {
  const navigate = useNavigate();
  const [historyEnabled, setHistoryEnabled] = useState(false);
  // The current user's permissions (affects what data sources they can view)
  const [userRoles, setUserRoles] = useState<UserRole[] | undefined | null>(null);

  useEffect(() => {
    history.listen((location) => {
      navigate(
        location.location.pathname,
        { state: location.location.state },
      );
    });
    setHistoryEnabled(true);
  }, []);

  const user = useUser();

  /**
   * Retrieves user permissions.
   */
  useEffect(() => {
    user?.getIdTokenResult()
      .then((idTokenResult) => {
        const roles = idTokenResult.claims?.roles as UserRole[] | undefined;
        setUserRoles(roles);
      })
      .catch((error) => { console.error(error); });
  }, [user]);

  return (
    <>
      {historyEnabled && (
        <Routes>
          <Route path="/">
            <Route path="login" element={<LoginPage />} />
            {errorAPI.doesErrorExist() && (<Route path="error" element={<ErrorPage />} />)}
            { user
                    && (
                    <>
                      {/* Default component if no permissions */}
                      <Route index element={<LandingPage userRoles={userRoles} />} />
                      {/* Google Sheets Sync page */}
                      {userRoles?.includes(UserRoles.GOOGLE_SHEETS) && (
                      <Route
                        path="/sheets"
                        element={(
                          <LandingPage
                            userRoles={userRoles}
                            source={DataSource.GOOGLE_SHEETS}
                          />
                          )}
                      />
                      )}
                      {/* Glide Sync page */}
                      {userRoles?.includes(UserRoles.GLIDE) && (
                      <Route
                        path="/glide"
                        element={(
                          <LandingPage
                            userRoles={userRoles}
                            source={DataSource.GLIDE}
                          />
                              )}
                      />
                      )}
                      {/* Entrata Sync page */}
                      {userRoles?.includes(UserRoles.ENTRATA) && (
                      <Route
                        path="/entrata"
                        element={(
                          <LandingPage
                            userRoles={userRoles}
                            source={DataSource.ENTRATA}
                          />
                          )}
                      />
                      )}
                      {/* Admin page */}
                      {userRoles?.includes(UserRoles.ADMIN) && (
                      <Route
                        path="admin"
                        element={<AdminPage userRoles={userRoles} />}
                      />
                      )}
                      {/*
                    Allows the 404 Page to be rendered if the user manually searches up the error
                    page.
                  */}
                      <Route path="*" element={<NotFoundPage />} />
                    </>
                    )}
          </Route>
        </Routes>
      )}
      {!historyEnabled && (<CenteredCircularProgress />)}
    </>
  );
};
