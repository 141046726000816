import { Add, CreateNewFolderOutlined } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { ManageGlideToBQJobModal } from './ManageGlideToBQJobModal';
import { deleteGlideToBQJob, getGlideToBQJobs } from '../../../../api/endpoints-api/EndpointsAPI';
import { GlideApp, GlideToBQJob } from './types';
import {
  JobRunHistory, TableGroupDoc, TableGroupMapping, TablesGroup, UpdateGroupInfo,
} from '../../landing-page-types';
import { getCoreGlideToBQColumns, GlideToBQDataGrid } from './GlideToBQDataGrid';
import { DeleteJobModal } from '../../google-sheet-sync/DeleteJobModal';
import { ActionsSpeedDial } from '../../common/ActionsSpeedDial';
import { JobGroupsDataGrid } from '../../common/JobGroupsDataGrid';
import { useAlert } from '../../../../api/alert-api/AlertAPI';

// Params for Glide to BQ component
interface GlideToBigQueryParams {
  groups: TableGroupDoc[],
  groupMappings: TableGroupMapping[]
  loadingGroups: boolean
  openCreateGroupModal: () => void
  updateGroupToDelete: (group: TablesGroup) => void
  refreshTable: () => void
  jobRunHistory: JobRunHistory[]
  glideApps: GlideApp[]
}

/**
 * Glide to BigQuery jobs sub-page.  User can view and manage Glide to BQ jobs.
 * @param groups job groups
 * @param groupMappings job group mappings (which job belongs to which group)
 * @param loadingGroups loading job groups
 * @param openCreateGroupModal function to open the create group modal
 * @param updateGroupToDelete function to open to delete group modal
 * @param refreshTable function to refresh groups
 * @param jobRunHistory all job history
 * @param glideApps all glide apps
 * @constructor
 */
export const GlideToBigQuery = function ({
  groups,
  groupMappings,
  loadingGroups,
  openCreateGroupModal,
  updateGroupToDelete,
  refreshTable,
  jobRunHistory,
  glideApps,
}: GlideToBigQueryParams) {
  const [createJobModalOpen, setCreateJobModalOpen] = useState<boolean>(false);
  const [jobs, setJobs] = useState<GlideToBQJob[]>([]);
  const [jobsLoading, setJobsLoading] = useState<boolean>(true);
  const [jobBeingEdited, setJobBeingEdited] = useState<GlideToBQJob | null>(null);
  const [jobBeingDeleted, setJobBeingDeleted] = useState<GlideToBQJob | null>(null);

  const updateJobBeingEdited = (job: GlideToBQJob | null) => setJobBeingEdited(job);
  const updateJobBeingDeleted = (job: GlideToBQJob | null) => setJobBeingDeleted(job);

  const speedDialActions = [
    { icon: <Add />, name: 'Sync new Glide table', action: () => setCreateJobModalOpen(true) },
    { icon: <CreateNewFolderOutlined />, name: 'Create new group', action: openCreateGroupModal },
  ];

  const alert = useAlert();

  /**
   * Retrieve jobs.
   */
  useEffect(() => {
    // refresh data only when the create/edit/delete modals are closing
    if (createJobModalOpen || jobBeingDeleted || jobBeingEdited) return;
    setJobsLoading(true);
    getGlideToBQJobs()
      .then((res: AxiosResponse<GlideToBQJob[]>) => setJobs(res.data))
      .catch(() => alert?.alert('Failed to retrieve jobs', 'error'))
      .finally(() => {
        refreshTable();
        setJobsLoading(false);
      });
  }, [createJobModalOpen, jobBeingDeleted, jobBeingEdited]);

  return (
    <>
      <div className="h-[80%]">
        <JobGroupsDataGrid
          detailContent={({ row }) => {
            const updateGroupInfo: UpdateGroupInfo = {
              groupMappings, groups, currentGroupId: row.groupId, refreshTable,
            };
            return GlideToBQDataGrid({
              rows: row.tables,
              updateGroupInfo,
              glideApps,
              updateJobBeingEdited,
              updateJobBeingDeleted,
              jobRunHistory,
            });
          }}
          jobs={jobs}
          getJobId={({ jobId }) => jobId}
          groupMappings={groupMappings}
          updateGroupToDelete={updateGroupToDelete}
          groups={groups}
          refreshTable={refreshTable}
          loading={jobsLoading || loadingGroups || !glideApps.length}
        />
      </div>
      {/* Actions panel / speed dial */}
      <div className="h-[10%] pt-4 flex flex-row justify-end">
        <ActionsSpeedDial actions={speedDialActions} />
      </div>
      {/* Create job modal */}
      {createJobModalOpen && (
      <ManageGlideToBQJobModal
        modalOpen={createJobModalOpen}
        closeModal={() => setCreateJobModalOpen(false)}
        jobs={jobs}
        glideApps={glideApps}
        groups={groups}
        groupMappings={groupMappings}
      />
      )}
      {/* Edit job modal */}
      {jobBeingEdited && (
      <ManageGlideToBQJobModal
        modalOpen={!!jobBeingEdited}
        closeModal={() => setJobBeingEdited(null)}
        jobs={jobs}
        glideApps={glideApps}
        jobBeingEdited={jobBeingEdited}
        groups={groups}
        groupMappings={groupMappings}
      />
      )}
      {/* Delete job modal */}
      {jobBeingDeleted && (
      <DeleteJobModal
        jobBeingDeleted={jobBeingDeleted}
        closeModal={() => updateJobBeingDeleted(null)}
        deleteJob={(
          deleteTableFromBigQuery: boolean,
        ) => deleteGlideToBQJob(jobBeingDeleted.jobId, deleteTableFromBigQuery)}
        columns={getCoreGlideToBQColumns(glideApps)}
        getRowId={(row: GlideToBQJob) => row.jobId}
        allowDeleteTable
        groupMappings={groupMappings}
      />
      )}
    </>
  );
};
