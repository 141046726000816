import { DataGridPro, GridRowParams } from '@mui/x-data-grid-pro';
import React, { useMemo } from 'react';
import { getGroupColumns, getGroupRows } from './helpers';
import { TableGroupDoc, TableGroupMapping, TablesGroup } from '../landing-page-types';

// Params for GroupsDataGrid component
interface JobGroupsDataGridParams<T> {
  detailContent: (params: GridRowParams<TablesGroup<T>>) => JSX.Element,
  jobs: T[],
  getJobId: (job: T) => string,
  groupMappings: TableGroupMapping[],
  updateGroupToDelete: (group: TablesGroup<unknown>) => void,
  groups: TableGroupDoc[]
  refreshTable: () => void,
  loading: boolean
}

export const JobGroupsDataGrid = function<T> ({
  detailContent, groupMappings, groups, jobs, getJobId, updateGroupToDelete, refreshTable, loading,
}: JobGroupsDataGridParams<T>) {
  // generate rows for groups datagrid - in this case, each row corresponds to a group of jobs
  const rows: TablesGroup<T>[] = useMemo(() => getGroupRows<T>(
    groupMappings,
    groups,
    jobs,
    getJobId,
  ), [groups, groupMappings, jobs]);

  // Generate columns for datagrid
  const columns = useMemo(
    () => getGroupColumns<T>(updateGroupToDelete, groups, refreshTable),
    [updateGroupToDelete, groups, refreshTable],
  );

  return (
    <DataGridPro
      rows={rows}
      columns={columns}
      loading={loading}
      getRowId={({ groupName }) => groupName}
      getDetailPanelContent={detailContent}
      getDetailPanelHeight={() => 'auto'}
      disableRowSelectionOnClick
    />
  );
};
