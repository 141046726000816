import { getFunctions, httpsCallable } from 'firebase/functions';
import { navigateTo } from '../navigation-api/NavigationAPI';
import { AlertHelper } from '../alert-api/AlertAPI';

/**
 * The ErrorInfo class is responsible for handling the last recorded error state.
 * The use case of this class is for recording error states between components,
 * most notably for use within ErrorPage.tsx
 */
class ErrorInfo {
  errorCode: string;

  errorMessage: string;

  errorPage: string;

  constructor() {
    this.errorCode = 'Unknown';
    this.errorMessage = 'Unknown';
    this.errorPage = 'error';
  }

  /**
     * A boolean check of whether the error has been set. This is achieved to see if both
     * the errorCode and errorMessage have remained in their default state.
     */
  doesErrorExist() {
    return !(this.errorCode === 'Unknown' && this.errorMessage === 'Unknown');
  }

  /**
     * A quick access method to navigate to the error page
     * instead of using the navigation API directly
     */
  navigateToErrorPage() {
    navigateTo(this.errorPage, true);
  }

  /**
     * Shorthand method to assign error code and message followed by navigating
     * to the error page.
     * @param {string} code - The Error Code produced by firebase (or custom made)
     * @param {string} message - Informational message on the error
     */
  setAndSend = (code: string, message: string) => {
    this.updateErrorCode(code);
    this.updateErrorMessage(message);
    this.navigateToErrorPage();
  };

  /**
     * Calls the 'sendBugReport' cloud function to email the maintainer (Jacob.mc)
     * Requires an authenticated user in order to call.
     */
  sendEmail = () => {
    const functions = getFunctions();
    const cloudFunctionsEmail = httpsCallable(functions, 'sendBugReport');
    void cloudFunctionsEmail(
      { errorCode: this.errorCode, errorMessage: this.errorMessage },
    )
      .then((result) => result);
  };

  // ===== Getters [Could've used keyword get, but it's fine] ===== //

  getErrorCode = () => this.errorCode;

  getErrorMessage = () => this.errorMessage;

  // ===== Setters [Could've used keyword set, but it's fine] ===== //

  updateErrorCode = (code: string) => {
    this.errorCode = code;
  };

  updateErrorMessage = (message: string) => {
    this.errorMessage = message;
  };
}

/**
 * Utility that shows an alert and throws an error with the provided message.
 * @param alert Alert context
 * @param alertMessage message for the alert
 * @param err passed error object
 */
export const alertError = (
  alert: AlertHelper | null,
  alertMessage: string,
  err?: any,
): Error => {
  alert?.alert(alertMessage, 'error');
  let errMessage: string | undefined;
  if (err instanceof Error) errMessage = err.stack;
  else errMessage = JSON.stringify(err);
  return new Error(errMessage || alertMessage);
};

// eslint-disable-next-line import/no-default-export
export default new ErrorInfo();
