import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { Box, IconButton } from '@mui/material';
import React from 'react';
import { Delete, Edit } from '@mui/icons-material';
import { GlideApp, GlideToBQJob } from './types';
import { StandaloneGroupSelector } from '../../common/StandaloneGroupSelector';
import { JobRunHistory, UpdateGroupInfo } from '../../landing-page-types';
import { getLastRunTimestamp, getShortenedTableId } from '../../../../api/landing-page-api/LandingPageAPI';
import { generateBigQueryURL, getSyncSchedule } from '../../common/helpers';
import { ManualSyncButton } from '../../common/ManualSyncButton';
import { RunStatusChip } from '../../common/RunStatusChip';

/**
 * Given a Glide app id, constructs Glide app URL.
 * @param appId glide app id
 */
const getGlideAppUrl = (appId: string) => `https://go.glideapps.com/app/${appId}/data`;

/**
 * Core non-interactive columns for Glide to BQ Datagrid.
 * These columns are shown in the job deletion modal.
 */
export const getCoreGlideToBQColumns = (glideApps: GlideApp[]): GridColDef<GlideToBQJob>[] => [
  {
    field: 'jobName',
    headerName: 'Job Name',
    flex: 1,
  },
  {
    field: 'Sync Schedule',
    flex: 1,
    valueGetter: ({ row }) => getSyncSchedule(row.refreshCron),
  },
  {
    field: 'userEmail',
    flex: 1,
    headerName: 'Owner',
  },
  {
    field: 'tableId',
    headerName: 'Table ID',
    flex: 1,
    renderCell: ({ row }) => {
      const shortId = getShortenedTableId(row.tableId);
      const [project, dataset, table] = row.tableId.split('.');
      const link = generateBigQueryURL(project, dataset, table);
      return (
        <a
          href={link}
          target="_blank"
          className="text-blue-500 underline"
          rel="noreferrer"
        >
          {shortId}
        </a>
      );
    },
  },
  {
    field: 'glideAppId',
    headerName: 'Glide App',
    flex: 1,
    renderCell: ({ row }) => {
      const appName = glideApps.find(({ appId }) => appId === row.glideAppId)?.appName;
      if (!appName) return <div>App not found</div>;
      const glideAppUrl = getGlideAppUrl(row.glideAppId);
      return (
        <a
          href={glideAppUrl}
          target="_blank"
          rel="noreferrer"
          className="text-blue-500 underline"
        >
          {appName}
        </a>
      );
    },
  },
  {
    field: 'glideTableId',
    headerName: 'Glide Table',
    flex: 1,
    valueGetter: ({ row }) => {
      const app = glideApps.find(({ appId }) => appId === row.glideAppId);
      if (!app) return 'App not found';
      const table = app.tables.find(({ id }) => id === row.glideTableId);
      return table?.name || 'Table not found in app';
    },
  },
];

/**
 * Columns for Glide to BQ jobs Datagrid
 * @param updateGroupInfo info to update groups
 * @param glideApps all glide apps
 * @param updateJobBeingEdited function to update job being edited
 * @param updateJobBeingDeleted function to update job being deleted
 * @param jobRunHistory all job history
 */
const getColumns = (
  updateGroupInfo: UpdateGroupInfo,
  glideApps: GlideApp[],
  updateJobBeingEdited: (job: GlideToBQJob | null) => void,
  updateJobBeingDeleted: (job: GlideToBQJob | null) => void,
  jobRunHistory: JobRunHistory[],
): GridColDef<GlideToBQJob>[] => {
  const coreColumns = getCoreGlideToBQColumns(glideApps);

  const interactiveColumns: GridColDef<GlideToBQJob>[] = [
    {
      field: 'lastRunStatus',
      headerName: 'Last Run Status',
      flex: 1,
      renderCell: ({ row }) => <RunStatusChip jobId={row.jobId} jobRunHistory={jobRunHistory} />,
    },
    {
      field: 'lastRunTimestamp',
      headerName: 'Last Run Time',
      flex: 1,
      valueGetter: ({ row }) => getLastRunTimestamp(jobRunHistory, row.jobId),
    },
    {
      field: 'editJob',
      headerName: 'Edit',
      headerAlign: 'center',
      flex: 0.5,
      renderCell: ({ row }) => (
        <IconButton
          className="mx-auto"
          onClick={() => updateJobBeingEdited(row)}
        >
          <Edit />
        </IconButton>
      ),
    },
    {
      field: 'deleteJob',
      headerName: 'Delete',
      headerAlign: 'center',
      flex: 0.5,
      renderCell: ({ row }) => (
        <IconButton
          className="mx-auto"
          onClick={() => updateJobBeingDeleted(row)}
        >
          <Delete />
        </IconButton>
      ),
    },
    {
      field: 'manualSync',
      headerName: 'Sync',
      headerAlign: 'center',
      flex: 0.5,
      renderCell: ({ row }) => ManualSyncButton(row.jobId),
    },
    {
      headerName: 'Group',
      field: 'group',
      width: 150,
      renderCell: ({ row }) => (
        <StandaloneGroupSelector
          tableId={row.jobId}
          refreshTable={updateGroupInfo.refreshTable}
          groupMappings={updateGroupInfo.groupMappings}
          groups={updateGroupInfo.groups}
          currentGroupId={updateGroupInfo.currentGroupId}
        />
      ),
    },
  ];

  return [...coreColumns, ...interactiveColumns];
};

// Params for GlideToBQ datagrid
interface GlideToBQDataGridParams {
  rows: GlideToBQJob[],
  updateGroupInfo: UpdateGroupInfo,
  glideApps: GlideApp[],
  updateJobBeingEdited: (job: GlideToBQJob | null) => void
  updateJobBeingDeleted: (job: GlideToBQJob | null) => void
  jobRunHistory: JobRunHistory[]
}

/**
 * Datagrid for Glide to BQ jobs.
 * @param rows glide to bq jobs
 * @param updateGroupInfo info to update groups
 * @param glideApps all glide apps
 * @param updateJobBeingEdited function to update job being edited
 * @param updateJobBeingDeleted function to update job being deleted
 * @param jobRunHistory all job history
 * @constructor
 */
export const GlideToBQDataGrid = function ({
  rows,
  updateGroupInfo,
  glideApps,
  updateJobBeingEdited,
  updateJobBeingDeleted,
  jobRunHistory,
}: GlideToBQDataGridParams) {
  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <DataGridPro
        rows={rows}
        columns={getColumns(updateGroupInfo, glideApps, updateJobBeingEdited, updateJobBeingDeleted, jobRunHistory)}
        getRowId={({ jobId }: GlideToBQJob) => jobId}
        disableRowSelectionOnClick
      />
    </Box>
  );
};
