import { Chip } from '@mui/material';
import React from 'react';
import { JobRunHistory, JobRunStatus } from '../landing-page-types';
import { getLastRun, lastJobRunNotFoundText } from '../../../api/landing-page-api/LandingPageAPI';

/**
 * Run status chip which displays the last run status of a job.
 * @param jobRunHistory job history
 * @param jobId id of the job
 * @constructor
 */
export const RunStatusChip = function ({ jobRunHistory, jobId }:{ jobRunHistory: JobRunHistory[], jobId: string }) {
  // get the last run of a given job - if the job never ran display default text
  const lastRun = getLastRun(jobRunHistory, jobId);
  if (!lastRun) return (<div>{lastJobRunNotFoundText}</div>);
  // if the job ran, show either a Success or Error chip
  const color = lastRun.runStatus === JobRunStatus.SUCCESS ? 'bg-green-300' : 'bg-red-300';
  const style = `${color} mx-auto text-gray-800`;
  return (<Chip className={style} label={lastRun.runStatus} />);
};
