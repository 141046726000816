import React, { useEffect, useState } from 'react';
import {
  Box, Tab, Tabs,
} from '@mui/material';
import { AxiosResponse } from 'axios';
import { GlideToBigQuery } from './glide-to-bq/GlideToBigQuery';
import { BigQueryToGlide } from './bq-to-glide/BigQueryToGlide';
import {
  JobRunHistory, TableGroupDoc, TableGroupMapping, TablesGroup,
} from '../landing-page-types';
import { watchJobRunHistory } from '../../../api/landing-page-api/LandingPageAPI';
import { GlideApp } from './glide-to-bq/types';
import { getGlideTables } from '../../../api/endpoints-api/EndpointsAPI';
import { useAlert } from '../../../api/alert-api/AlertAPI';

/**
 * Represents a tab on the Glide page.
 */
interface GlideTab {
  id: number
  name: string,
  component: JSX.Element
}

// Params for GlideSync component
interface GlideSyncParams {
  groups: TableGroupDoc[],
  groupMappings: TableGroupMapping[]
  loadingGroups: boolean
  openCreateGroupModal: () => void
  updateGroupToDelete: (group: TablesGroup) => void
  refreshTable: () => void
}

/**
 * Page for Glide sync jobs, from Glide => BQ and BQ => Glide.
 * @param groups job groups
 * @param groupMappings job group mappings
 * @param loadingGroups loading job groups
 * @param openCreateGroupModal function to open create group modal
 * @param updateGroupToDelete function to set which group to delete
 * @param refreshTable function to refresh groups
 * @constructor
 */
export const GlideSync = function ({
  groups,
  groupMappings,
  loadingGroups,
  openCreateGroupModal,
  updateGroupToDelete,
  refreshTable,
}: GlideSyncParams) {
  const [jobRunHistory, setJobRunHistory] = useState<JobRunHistory[]>([]);
  const [glideApps, setGlideApps] = useState<GlideApp[]>([]);

  const alert = useAlert();

  // Gets real-time jobRun data updates from Firestore
  useEffect(() => {
    const unsubscribe = watchJobRunHistory(setJobRunHistory);
    return () => unsubscribe();
  }, []);

  /**
   * Gets all Glide tables.
   */
  useEffect(() => {
    getGlideTables()
      .then((res: AxiosResponse<GlideApp[]>) => setGlideApps(res.data))
      .catch(() => alert?.alert('Failed to retrieve Glide data', 'error'));
  }, []);

  // Props for tab components
  const tabProps = {
    groups,
    groupMappings,
    loadingGroups,
    openCreateGroupModal,
    updateGroupToDelete,
    refreshTable,
    jobRunHistory,
    glideApps,
  };

  const tabs: GlideTab[] = [
    {
      id: 1,
      name: 'To BigQuery',
      component: <GlideToBigQuery {...tabProps} />,
    },
    {
      id: 2,
      name: 'To Glide',
      component: <BigQueryToGlide {...tabProps} />,
    },
  ];

  const [selectedTabId, setSelectedTabId] = useState<number>(tabs[0].id);

  const selectedTab = tabs.find(({ id }) => id === selectedTabId);

  if (!selectedTab) return null;

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={selectedTabId}
          onChange={(e, value) => setSelectedTabId(value as number)}
        >
          {tabs.map((tab) => (<Tab label={tab.name} value={tab.id} />))}
        </Tabs>
      </Box>
      {selectedTab.component}
    </>
  );
};
