import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './i18n';
import { BrowserRouter as Router } from 'react-router-dom';
import { StyledEngineProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license-pro';
import { App } from './App';
import { reportWebVitals } from './reportWebVitals';
import { AlertProvider, UserProvider } from './components/api';
import { CenteredCircularProgress } from './components/helper';
// eslint-disable-next-line max-len
LicenseInfo.setLicenseKey('3899db97132bcf9ceecf5a7e302e1125Tz0xMDM0OTMsRT0xNzY0ODcxMjUzMDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI=');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <StyledEngineProvider injectFirst>
    <Router>
      <Suspense fallback={<CenteredCircularProgress />}>
        <AlertProvider>
          <UserProvider>
            <html lang="en" className="h-full bg-gray-100">
              <body className="h-full">
                <App />
              </body>
            </html>
          </UserProvider>
        </AlertProvider>
      </Suspense>
    </Router>
  </StyledEngineProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
