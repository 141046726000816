import React, { useEffect, useMemo, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Add, CreateNewFolderOutlined } from '@mui/icons-material';
import { ManageJobModal } from './manage-job-modal/ManageJobModal';
import { DeleteJobModal } from './DeleteJobModal';
import {
  JobRunHistory, SheetSyncJob, TableGroupDoc, TableGroupMapping, TablesGroup, UpdateGroupInfo,
} from '../landing-page-types';
import { deleteJob, getJobs } from '../../../api/endpoints-api/EndpointsAPI';
import { filterJobsForUser, watchJobRunHistory } from '../../../api/landing-page-api/LandingPageAPI';
import { alertError } from '../../../api/error-api/ErrorAPI';
import { useAlert } from '../../../api/alert-api/AlertAPI';
import { useUser } from '../../../api';
import { coreColumns, JobsDataGrid } from './jobs-datagrid/JobsDataGrid';
import { ActionsSpeedDial } from '../common/ActionsSpeedDial';
import { JobGroupsDataGrid } from '../common/JobGroupsDataGrid';

// Params for GoogleSheetsSync component.
interface GoogleSheetsSyncParams {
  groups: TableGroupDoc[],
  groupMappings: TableGroupMapping[]
  loadingGroups: boolean
  openCreateGroupModal: () => void
  refreshTable: () => void
  updateGroupToDelete: (group: TablesGroup) => void
}

/**
 * Component to manage sync jobs between Google Sheets and BigQuery.
 * @constructor
 */
export const GoogleSheetsSync = function ({
  groups, groupMappings, loadingGroups, openCreateGroupModal, refreshTable, updateGroupToDelete,
}: GoogleSheetsSyncParams) {
  const [createJobModalOpen, setCreateJobModalOpen] = useState<boolean>(false);
  // id of a job being deleted - dictates whether delete modal is open
  const [deleteModalJobId, setDeleteModalJobId] = useState<string>('');
  // id of a job being edited - dictates whether edit modal is open
  const [editJobModalId, setEditJobModalId] = useState<string>('');
  // jobs rows which populate the jobs Datagrid
  const [jobRows, setJobRows] = useState<SheetSyncJob[]>([]);
  const [jobsLoading, setJobsLoading] = useState<boolean>(true);
  // history of all job runs
  const [jobRunHistory, setJobRunHistory] = useState<JobRunHistory[]>([]);

  const alert = useAlert();
  const user = useUser();

  // retrieves all jobs viewable to the current user
  useEffect(() => {
    if (!user) return;
    getJobs()
      .then(({ data }: AxiosResponse<SheetSyncJob[]>) => {
        // filter jobs for relevant jobs
        const rows = filterJobsForUser(data, user);
        setJobRows(rows);
      })
      .catch((err) => { throw alertError(alert, 'Failed to pull jobs', err); })
      .finally(() => {
        refreshTable();
        setJobsLoading(false);
      });
  }, [createJobModalOpen, deleteModalJobId, editJobModalId]);

  // Gets real-time jobRun data updates from Firestore
  useEffect(() => {
    const unsubscribe = watchJobRunHistory(setJobRunHistory);
    return () => unsubscribe();
  }, []);

  const updateDeleteModalJobId = (id: string) => setDeleteModalJobId(id);
  const updateEditModalJobId = (id: string) => setEditJobModalId(id);
  // job which is being edited (if there is one)
  const jobBeingEdited = useMemo(
    () => jobRows.find(({ jobId }) => jobId === editJobModalId),
    [jobRows, editJobModalId],
  );
    // job which is being deleted (if there is one)
  const jobBeingDeleted = useMemo(
    () => jobRows.find(({ jobId }) => jobId === deleteModalJobId),
    [jobRows, deleteModalJobId],
  );

  // Actions for speed dial component
  const speedDialActions = [
    { icon: <Add />, name: 'Sync new sheet', action: () => setCreateJobModalOpen(true) },
    { icon: <CreateNewFolderOutlined />, name: 'Create new group', action: openCreateGroupModal },
  ];

  return (
    <>

      {/*  Jobs datagrid */}
      <div className="h-[90%]">
        <JobGroupsDataGrid
          detailContent={({ row }) => {
            const updateGroupInfo: UpdateGroupInfo = {
              groupMappings, groups, currentGroupId: row.groupId, refreshTable,
            };
            return JobsDataGrid({
              jobRows: row.tables,
              updateDeleteModalJobId,
              updateEditModalJobId,
              interactive: true,
              jobsLoading,
              jobRunHistory,
              updateGroupInfo,
            });
          }}
          jobs={jobRows}
          getJobId={({ jobId }) => jobId}
          groupMappings={groupMappings}
          updateGroupToDelete={updateGroupToDelete}
          groups={groups}
          refreshTable={refreshTable}
          loading={jobsLoading || loadingGroups}
        />
      </div>
      {/* Create new job modal */}
      {createJobModalOpen && (
      <ManageJobModal
        modalOpen={createJobModalOpen}
        closeModal={() => setCreateJobModalOpen(false)}
        jobs={jobRows}
        groups={groups}
        groupMappings={groupMappings}
      />
      )}
      {/* Edit job modal */}
      {editJobModalId && jobBeingEdited && (
      <ManageJobModal
        modalOpen={!!editJobModalId}
        closeModal={() => setEditJobModalId('')}
        existingSyncJob={jobBeingEdited}
        jobs={jobRows}
        groups={groups}
        groupMappings={groupMappings}
      />
      )}
      {/* Delete job modal */}
      {deleteModalJobId && jobBeingDeleted && (
      <DeleteJobModal
        jobBeingDeleted={jobBeingDeleted}
        closeModal={() => updateDeleteModalJobId('')}
        deleteJob={(deleteTableFromBigQuery: boolean) => deleteJob(deleteModalJobId, deleteTableFromBigQuery)}
        columns={coreColumns}
        getRowId={(row: SheetSyncJob) => row.jobId}
        allowDeleteTable
        groupMappings={groupMappings}
      />
      )}
      {/*  Sync new sheet button */}
      <div className="h-[10%] pt-4 flex flex-row justify-end">
        <ActionsSpeedDial actions={speedDialActions} />
      </div>
    </>
  );
};
