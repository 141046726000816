import React, { useState } from 'react';
import {
  FormControl, MenuItem, Select, SelectChangeEvent,
} from '@mui/material';
import { useAlert } from '../../../api/alert-api/AlertAPI';
import { StandaloneGroupSelectorParams } from './params';
import { updateTableGroup } from './helpers';

/**
 * Selector component which allows user to change the given group of a table independently (hence "standalone").
 * @param tableId id of the table or job that we are updating
 * @param refreshTable function to refresh the parent table
 * @param groupMappings mappings of table to group
 * @param groups all groups
 * @param currentGroupId the id of the group that the table is currently in
 * @constructor
 */
export const StandaloneGroupSelector = function ({
  tableId, refreshTable, groupMappings, groups, currentGroupId,
}: StandaloneGroupSelectorParams) {
  const [selectedGroup, setSelectedGroup] = useState<string>(currentGroupId);

  const alert = useAlert();

  /**
   * Update a given's table membership in a given group.
   * @param event Selector change event
   */
  const updateGroup = (event: SelectChangeEvent) => {
    const newGroup = event.target.value;

    updateTableGroup(newGroup, tableId, groupMappings)
      .then(() => {
        // we set this state so that the value populates immediately, even though we refresh the table right after
        setSelectedGroup(newGroup);
        alert?.alert('Successfully updated group', 'success');
        refreshTable();
      })
      .catch(() => {
        alert?.alert('Failed to update group', 'error');
      });
  };

  return (
    <FormControl fullWidth>
      <Select
        value={selectedGroup}
        placeholder="Group"
        onChange={updateGroup}
        variant="standard"
      >
        <MenuItem className="italic" value="">None</MenuItem>
        {groups.map((group) => <MenuItem value={group.id}>{group.name}</MenuItem>)}
      </Select>
    </FormControl>
  );
};
