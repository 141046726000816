import {
  Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Skeleton,
} from '@mui/material';
import React, { useMemo } from 'react';
import Paper from '@mui/material/Paper';
import { PreviewRowsParams } from '../../landing-page-params';
import { getHeadersFromPreviewRows } from '../../../../api/landing-page-api/LandingPageAPI';

export const PreviewRows = function ({ rows, loadingSheetData, userDoesNotHaveSheetAccess }: PreviewRowsParams) {
  if (loadingSheetData) return <Skeleton variant="rectangular" className="w-full h-64" />;
  if (userDoesNotHaveSheetAccess) {
    return (
      <div className="w-full h-64 flex flex-row justify-center items-center">
        <div>You do not have permissions to view the sheet data for this job</div>
      </div>
    );
  }
  const headers = useMemo(() => getHeadersFromPreviewRows(rows), [rows]);

  if (!rows.length) return <div className="w-full mx-auto h-64" />;

  return (
    <TableContainer component={Paper} className="w-full mx-auto h-64">
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <TableCell key={header}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={Math.random()}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              {headers.map((header) => {
                // if the row does not include the header, this means it is an empty cell and can be represented by ''
                const value = row[header] || '';
                // We only want to display the first 40 chars for long strings
                const truncatedValue = value.length > 40 ? `${value.slice(0, 40)}...` : value;
                return (
                  <TableCell key={Math.random()}>{truncatedValue}</TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
