import React from 'react';

export enum PageType {
  Home = '/',
  Admin = '/admin',
  GoogleSheets = '/sheets',
  Entrata = '/entrata',
  Glide = '/glide',
}

export interface NavigationItem {
  // internal identifier
  id: PageType,
  // The URL destination
  href: string,
  // The displayName
  name: string,
  // Restrict to admin users
  adminRestricted: boolean,
  // icon
  icon: (props: React.ComponentProps<'svg'>) => JSX.Element;
}
