import React, { useState } from 'react';
import { IconButton, TextField } from '@mui/material';
import { Check, Clear, Edit } from '@mui/icons-material';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../../firebase-config';
import { useAlert } from '../../../api/alert-api/AlertAPI';
import { UpdateGroupNameInputParams } from './params';
import { groupDefinitionsPath } from '../landing-page-types';
import { UNSORTED_GROUP_ID } from './TableGroupActions';

/**
 * Input that allows user to modify a group name.
 * @param group the group in question
 * @param groups all groups (filtered by data source)
 * @param refreshTable refreshes the dataGrid
 * @constructor
 */
export const UpdateGroupNameInput = function ({ group, groups, refreshTable }: UpdateGroupNameInputParams) {
  const [editing, setEditing] = useState<boolean>(false);
  const [groupName, setGroupName] = useState<string>(group.groupName);

  const alert = useAlert();

  /**
   * Renames a given group to the user-provided name.
   */
  const renameGroup = () => {
    updateDoc(doc(db, groupDefinitionsPath, group.groupId), { name: groupName })
      .then(() => {
        alert?.alert('Successfully updated group name', 'success');
        setEditing(false);
        refreshTable();
      })
      .catch(() => { alert?.alert('Failed to update group name', 'error'); });
  };

  // There is a group name conflict if a group with the same name but a different group ID exists already.
  // Groups are already filtered by data source at this point so no need to check for that.
  const groupNameConflict = !!groups.find(({ name, id }) => name === groupName && id !== group.groupId);
  const disabled = groupNameConflict || !groupName;

  /**
   * Cancels the editing process for group name.
   */
  const cancelGroupNameChange = () => {
    setEditing(false);
    setGroupName(group.groupName);
  };

  // do not allow user to rename default "unsorted" group
  if (group.groupId === UNSORTED_GROUP_ID) return <div>{group.groupName}</div>;

  return (
    <div className="flex flex-row items-center gap-x-2">
      {editing ? (
        <>
          {/* Group name textfield */}
          <TextField
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
          />
          {/* Submit group name button */}
          <IconButton
            onClick={renameGroup}
            disabled={disabled}
          >
            <Check />
          </IconButton>
          {/* Cancel group name change button */}
          <IconButton
            onClick={cancelGroupNameChange}
          >
            <Clear />
          </IconButton>
        </>
      ) : (
        <>
          <div>{group.groupName}</div>
          {/* Edit group name button */}
          <IconButton
            onClick={() => setEditing(true)}
          >
            <Edit />
          </IconButton>
        </>
      )}
    </div>
  );
};
