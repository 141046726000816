import axios from 'axios';
import { CreateSyncJobReq, EditJobReq, ManualSyncReq } from '../../pages/landing-page/landing-page-types';
import { UserRole } from '../../pages/admin-page/admin-types';

import {
  CreateBQToGlideJobPayload, EditBQToGlideJobPayload,
} from '../../pages/landing-page/glide-sync/bq-to-glide/types';
import {
  CreateGlideToBQJobPayload,
  EditGlideToBQJobPayload,
} from '../../pages/landing-page/glide-sync/glide-to-bq/types';

// The URL for the local functions emulator (when running app locally)
const localFunctions = 'http://127.0.0.1:5001/wreg-sheet-sync/us-central1/sheetSyncApp';
// The URL for the deployed Express function (when in deployment)
const deployedFunctions = 'https://us-central1-wreg-sheet-sync.cloudfunctions.net/sheetSyncApp';

// Toggle backend based on execution environment
const backendUrl = process.env.REACT_APP_USE_LOCAL_FUNCTIONS === 'true' ? localFunctions : deployedFunctions;

// These represent different Express controllers on our backend
const sheetsEndpoint = `${backendUrl}/sheets`;
const jobsEndpoint = `${backendUrl}/jobs`;
const entrataEndpoint = `${backendUrl}/entrata`;
const usersEndpoint = `${backendUrl}/users`;
const glideEndpoint = `${backendUrl}/glide`;
const bigQueryEndpoint = `${backendUrl}/bigquery`;

/**
 * Retrieves sheet names of a given Google Sheets workbook.
 * @param workbookId the ID of the workbook
 * @param userEmail email of the current user
 */
export const getWorkbookSheets = (
  workbookId: string,
  userEmail: string,
) => axios.get(`${sheetsEndpoint}/${workbookId}/${userEmail}`);

/**
 * Retrieves first 5 rows of a given sheet in a Google Sheets workbook.
 * @param workbookId ID of the workbook
 * @param userEmail email of the current user
 * @param sheetName name of the sheet that the rows are being retrieved from
 */
export const getPreviewRows = (
  workbookId: string,
  userEmail: string,
  sheetName: string,
) => axios.get(`${sheetsEndpoint}/rows/${workbookId}/${userEmail}/${sheetName}`);

/**
 * Creates a sync job within the jobs table in Postgres.
 * @param payload
 */
export const createSyncJob = (
  payload: CreateSyncJobReq,
) => axios.post(`${jobsEndpoint}/create-job`, payload);

/**
 * Retrieves all jobs from the jobs table in Postgres.
 */
export const getJobs = () => axios.get(jobsEndpoint);

/**
 * Deletes a job from the jobs table in Postgres.
 * @param jobId ID of the job to delete
 * @param dropTable whether or not to delete the Bigquery table associated with the job being deleted
 */
export const deleteJob = async (jobId: string, dropTable: boolean) => {
  const body = { dropTable };
  return axios.post(`${jobsEndpoint}/delete-job/${jobId}`, body);
};

/**
 * Edit a job in the jobs table in Postgres.
 * @param jobId ID of the job being edited
 * @param payload the job fields being changed
 */
export const editSyncJob = async (
  jobId: string,
  payload: EditJobReq,
) => axios.post(`${jobsEndpoint}/edit-job/${jobId}`, payload);

/**
 * Triggers manual sync of a job.
 * @param syncReq the request data to trigger a manual sync
 */
export const manualSync = async (syncReq: ManualSyncReq) => axios.post(`${jobsEndpoint}/sync`, syncReq);

/**
 * Retrieves Entrata tables metadata.
 */
export const getEntrataTables = () => axios.get(`${entrataEndpoint}/get-tables`);

/**
 * Triggers manual sync of an Entrata table to BQ.
 * @param table table name to sync
 */
export const manualEntrataSync = (table: string) => axios.post(`${entrataEndpoint}/manual-sync`, { table });

/**
 * Creates a scheduler for an Entrata table sync job.
 * @param data the table name to sync and the sync schedule
 */
export const createEntrataScheduler = (data: {
  tableName: string,
  schedule: string
}) => axios.post(`${entrataEndpoint}/create-schedule`, data);

/**
 * Deletes a scheduler for an Entrata table sync job.
 * @param data the table name for the job that the scheduler triggers
 */
export const deleteEntrataScheduler = (data: {
  tableName: string
}) => axios.post(`${entrataEndpoint}/delete-schedule`, data);

/**
 * Get all data for all users of the app.
 */
export const getAppUsers = () => axios.get(`${usersEndpoint}/get-users`);

/**
 * Add or remove a role from a user.
 * @param data payload specifying which user to update, what role to modify, and whether to add or remove the role
 */
export const updateAppUserRole = (data: {
  uid: string,
  role: UserRole,
  addRole: boolean
}) => axios.post(`${usersEndpoint}/update-role`, data);

/**
 * Gets all Glide apps and the tables within them.
 */
export const getGlideTables = () => axios.get(`${glideEndpoint}/get-tables`);

/**
 * Get preview rows from a Glide table.
 * @param data request payload
 */
export const getGlidePreviewRows = (
  data: { appId: string, tableId: string },
) => axios.post(`${glideEndpoint}/get-preview`, data);

/**
 * Gets all tables from Glide dataset in sheet-sync project in BQ.
 */
export const getGlideDatasetTablesBQ = () => axios.get(`${bigQueryEndpoint}/get-glide-dataset-tables`);

/**
 * Gets all Glide to BQ jobs.
 */
export const getGlideToBQJobs = () => axios.get(`${glideEndpoint}/get-glide-to-bq-jobs`);

/**
 * Create a Glide to BQ job.
 * @param data create glide to BQ job payload
 */
export const createGlideToBQJob = (
  data: CreateGlideToBQJobPayload,
) => axios.post(`${glideEndpoint}/create-glide-to-bq-job`, data);

/**
 * Edit a Glide to BQ job.
 * @param data edit glide to BQ job payload
 */
export const editGlideToBQJob = (
  data: EditGlideToBQJobPayload,
) => axios.post(`${glideEndpoint}/edit-glide-to-bq-job`, data);

/**
 * Delete a Glide to BQ job.
 * @param jobId id of the job to delete
 * @param deleteTableFromBigQuery whether to delete the synced table from BQ
 */
export const deleteGlideToBQJob = (
  jobId: string,
  deleteTableFromBigQuery: boolean,
) => axios.post(`${glideEndpoint}/delete-glide-to-bq-job`, { jobId, deleteTableFromBigQuery });

/**
 * Get all BQ to Glide jobs.
 */
export const getBQToGlideJobs = () => axios.get(`${glideEndpoint}/get-bq-to-glide-jobs`);

/**
 * Delete BQ to Glide job.
 * @param jobId id of the job to delete
 */
export const deleteBQToGlideJob = (jobId: string) => axios.post(`${glideEndpoint}/delete-bq-to-glide-job`, { jobId });

/**
 * Get all GCP projects that the user has access to && that have BQ tables.
 * @param userEmail the user requesting the projects
 */
export const getBQProjects = (userEmail: string) => axios.post(`${bigQueryEndpoint}/get-projects`, { userEmail });

/**
 * Get all BQ datasets and tables from a given project.
 * @param projectId the id of the project
 */
export const getBQTables = (projectId: string) => axios.post(`${glideEndpoint}/get-bq-tables`, { projectId });

/**
 * Get preview rows of a table in BQ.
 * @param data payload to get preview rows
 */
export const getBQPreview = (
  data: { projectId: string, datasetId: string, tableId: string },
) => axios.post(`${glideEndpoint}/get-bq-preview`, data);

/**
 * Create a BQ to Glide Job.
 * @param data payload to create a BQ to Glide job
 */
export const createBQToGlideJob = (
  data: CreateBQToGlideJobPayload,
) => axios.post(`${glideEndpoint}/create-bq-to-glide-job`, data);

/**
 * Edit a BQ to Glide job
 * @param data payload to edit a BQ to Glide job
 */
export const editBQToGlideJob = (
  data: EditBQToGlideJobPayload,
) => axios.post(`${glideEndpoint}/edit-bq-to-glide-job`, data);
