import React, { useEffect, useState } from 'react';
import { Add, CreateNewFolderOutlined } from '@mui/icons-material';
import { AxiosResponse } from 'axios';
import {
  JobRunHistory, TableGroupDoc, TableGroupMapping, TablesGroup, UpdateGroupInfo,
} from '../../landing-page-types';
import { GlideApp } from '../glide-to-bq/types';
import { deleteBQToGlideJob, getBQProjects, getBQToGlideJobs } from '../../../../api/endpoints-api/EndpointsAPI';
import { BQToGlideDataGrid, coreBQToGlideColumns } from './BQToGlideDataGrid';
import { ActionsSpeedDial } from '../../common/ActionsSpeedDial';
import { ManageBQToGlideJobModal } from './ManageBQToGlideJobModal';
import { DeleteJobModal } from '../../google-sheet-sync/DeleteJobModal';
import { BQProject, BQToGlideJob } from './types';
import { JobGroupsDataGrid } from '../../common/JobGroupsDataGrid';
import { useAlert } from '../../../../api/alert-api/AlertAPI';
import { useUser } from '../../../../api';

// Params for BQ to Glide component
interface BigQueryToGlideParams {
  groups: TableGroupDoc[],
  groupMappings: TableGroupMapping[]
  loadingGroups: boolean
  openCreateGroupModal: () => void
  updateGroupToDelete: (group: TablesGroup) => void
  refreshTable: () => void
  jobRunHistory: JobRunHistory[]
  glideApps: GlideApp[]
}

/**
 * BigQuery to Glide jobs sub-page.  User can view and manage BQ to Glide jobs.
 * @param groups job groups
 * @param groupMappings job group mappings (which job belongs to which group)
 * @param loadingGroups loading job groups
 * @param openCreateGroupModal function to open the create group modal
 * @param updateGroupToDelete function to open to delete group modal
 * @param refreshTable function to refresh groups
 * @param jobRunHistory all job history
 * @param glideApps all glide apps
 * @constructor
 */
export const BigQueryToGlide = function ({
  groups,
  groupMappings,
  loadingGroups,
  openCreateGroupModal,
  updateGroupToDelete,
  refreshTable,
  jobRunHistory,
  glideApps,
}: BigQueryToGlideParams) {
  const [createJobModalOpen, setCreateJobModalOpen] = useState<boolean>(false);
  const [jobs, setJobs] = useState<BQToGlideJob[]>([]);
  const [jobsLoading, setJobsLoading] = useState<boolean>(true);
  const [jobBeingEdited, setJobBeingEdited] = useState<BQToGlideJob | null>(null);
  const [jobBeingDeleted, setJobBeingDeleted] = useState<BQToGlideJob | null>(null);
  // all BigQuery projects
  const [projects, setProjects] = useState<BQProject[]>([]);
  const [loadingProjects, setLoadingProjects] = useState<boolean>(true);

  const updateJobBeingEdited = (job: BQToGlideJob | null) => setJobBeingEdited(job);
  const updateJobBeingDeleted = (job: BQToGlideJob | null) => setJobBeingDeleted(job);

  const speedDialActions = [
    { icon: <Add />, name: 'Sync new BQ table to Glide', action: () => setCreateJobModalOpen(true) },
    { icon: <CreateNewFolderOutlined />, name: 'Create new group', action: openCreateGroupModal },
  ];

  const alert = useAlert();
  const user = useUser();

  /**
   * Retrieves all BQ to Glide jobs.
   */
  useEffect(() => {
    // refresh data only when the create/edit/delete modals are closing
    if (createJobModalOpen || jobBeingDeleted || jobBeingEdited) return;
    setJobsLoading(true);

    getBQToGlideJobs()
      .then((res: AxiosResponse<BQToGlideJob[]>) => setJobs(res.data))
      .catch(() => alert?.alert('Failed to retrieve jobs', 'error'))
      .finally(() => {
        refreshTable();
        setJobsLoading(false);
      });
  }, [createJobModalOpen, jobBeingDeleted, jobBeingEdited]);

  /**
   * Retrieves all BigQuery projects.
   */
  useEffect(() => {
    if (!user?.email) return;
    setLoadingProjects(true);
    getBQProjects(user.email)
      .then((res: AxiosResponse<BQProject[]>) => setProjects(res.data))
      .catch(() => alert?.alert('Failed to retrieve projects', 'error'))
      .finally(() => setLoadingProjects(false));
  }, []);

  const loadingDataGrid = jobsLoading || loadingGroups || !glideApps.length || loadingProjects;

  return (
    <>
      <div className="h-[80%]">
        <JobGroupsDataGrid
          detailContent={({ row }) => {
            const updateGroupInfo: UpdateGroupInfo = {
              groupMappings, groups, currentGroupId: row.groupId, refreshTable,
            };
            return BQToGlideDataGrid({
              rows: row.tables,
              updateGroupInfo,
              updateJobBeingEdited,
              updateJobBeingDeleted,
              jobRunHistory,
            });
          }}
          jobs={jobs}
          getJobId={({ jobId }) => jobId}
          groupMappings={groupMappings}
          updateGroupToDelete={updateGroupToDelete}
          groups={groups}
          refreshTable={refreshTable}
          loading={loadingDataGrid}
        />
      </div>
      {/* Actions panel / speed dial */}
      <div className="h-[10%] pt-4 flex flex-row justify-end">
        <ActionsSpeedDial actions={speedDialActions} />
      </div>
      {/* Create job modal */}
      {createJobModalOpen && (
        <ManageBQToGlideJobModal
          modalOpen={createJobModalOpen}
          closeModal={() => setCreateJobModalOpen(false)}
          jobs={jobs}
          glideApps={glideApps}
          projects={projects}
          groups={groups}
          groupMappings={groupMappings}
        />
      )}
      {/* Edit job modal */}
      {jobBeingEdited && (
        <ManageBQToGlideJobModal
          modalOpen={!!jobBeingEdited}
          closeModal={() => setJobBeingEdited(null)}
          jobs={jobs}
          glideApps={glideApps}
          jobBeingEdited={jobBeingEdited}
          projects={projects}
          groups={groups}
          groupMappings={groupMappings}
        />
      )}
      {/* Delete job modal */}
      {jobBeingDeleted && (
        <DeleteJobModal
          jobBeingDeleted={jobBeingDeleted}
          closeModal={() => updateJobBeingDeleted(null)}
          deleteJob={() => deleteBQToGlideJob(jobBeingDeleted.jobId)}
          columns={coreBQToGlideColumns}
          getRowId={(row: BQToGlideJob) => row.jobId}
          groupMappings={groupMappings}
        />
      )}
    </>
  );
};
