import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../../firebase-config';
import {
  DataSource, groupDefinitionsPath, groupMappingsPath,
  TableGroupDoc, TableGroupMapping,
} from './landing-page-types';

/**
 * Gets all groups data from Firestore, filtered by data source
 * @param source the data source to filter by
 */
export const getGroupsData = async (source: DataSource) => {
  // Get all groups docs
  const groupsRes = await getDocs(collection(db, groupDefinitionsPath));
  const groups = groupsRes.docs.map((groupDoc) => groupDoc.data()) as TableGroupDoc[];
  // Filter docs by data source
  const groupDocs = groups.filter((doc) => doc.source === source);
  const relevantGroupIds = groupDocs.map(({ id }) => id);
  // Get all group mappings docs
  const mappingsRes = await getDocs(collection(db, groupMappingsPath));
  const mappingsDocs = mappingsRes.docs.map((groupDoc) => groupDoc.data()) as TableGroupMapping[];
  // Filter mappings for mappings relevant to data source
  const mappings = mappingsDocs.filter(({ groupId }) => relevantGroupIds.includes(groupId));
  return { groupDocs, mappings };
};
