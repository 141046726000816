import { User } from 'firebase/auth';
import dayjs from 'dayjs';
import {
  collection, DocumentData, onSnapshot, query,
} from 'firebase/firestore';
import {
  EditJobReq,
  FrequencyOptions,
  JobRunHistory,
  JobValues, SheetRow,
  SheetSyncJob, TableGroupMapping,
  WriteDisposition,
} from '../../pages/landing-page/landing-page-types';
import { db } from '../../../firebase-config';

// The default day of week when selecting job frequency
export const defaultDayOfWeek = 0;
// The default day of month when selecting job frequency
export const defaultDayOfMonth = 1;
// The default sync frequency when selecting job frequency
export const defaultFrequency = FrequencyOptions.DAILY;

/**
 * When selecting a job frequency, the day of week or day of month of the frequency is encoded into "frequency detail".
 * We can decode this frequency detail back into day of week/day of month (for example, when the user is editing job frequency).
 * @param freqDetail frequency detail (can be null if sync freq is not "monthly" or "weekly")
 * @param freqType frequency type
 */
export const decodeFrequencyDetails = (freqDetail: number | null, freqType: FrequencyOptions) => {
  const selectedDayOfMonth = freqDetail && freqType === FrequencyOptions.MONTHLY ? freqDetail : defaultDayOfMonth;
  const selectedDayOfWeek = freqDetail && freqType === FrequencyOptions.WEEKLY ? freqDetail : defaultDayOfWeek;
  return { selectedDayOfWeek, selectedDayOfMonth };
};

/**
 * Generates the initial Job values for the ManageJobModal.  Values are determined based on whether modal
 * is in Create or Edit mode (i.e. whether or not a syncJob is passed into the modal).
 * @param groupMappings job group mappings
 * @param syncJob existing job being edited.  If present, its values will serve as the init values.
 */
export const getJobModalInitValues = (groupMappings: TableGroupMapping[], syncJob?: SheetSyncJob): JobValues => {
  const defaultValues = {
    workbookUrl: '',
    selectedSheet: '',
    shared: true,
    tableName: '',
    overwriteOnSync: true,
    identifierColumn: '',
    selectedFrequency: defaultFrequency,
    selectedDayOfWeek: defaultDayOfWeek,
    selectedDayOfMonth: defaultDayOfMonth,
    jobName: '',
    comments: '',
    selectedGroup: '',
  };

  if (!syncJob) return defaultValues;

  const {
    workbookUrl, sheetName, shared, tableName,
    writeMethod, uuidColumn, displaySyncFreqDetail, displaySyncFreqType, jobName, comments, jobId,
  } = syncJob;

  const {
    selectedDayOfMonth, selectedDayOfWeek,
  } = decodeFrequencyDetails(displaySyncFreqDetail, displaySyncFreqType);

  // find selected group (or set as empty if job is not in a group)
  const group = groupMappings.find(({ tableId }) => tableId === jobId)?.groupId || '';

  return {
    workbookUrl,
    selectedSheet: sheetName,
    shared,
    tableName,
    overwriteOnSync: writeMethod === WriteDisposition.OVERWRITE,
    identifierColumn: uuidColumn || '',
    selectedFrequency: displaySyncFreqType,
    selectedDayOfMonth,
    selectedDayOfWeek,
    jobName,
    comments,
    selectedGroup: group,
  };
};

/**
 * Filters jobs by namespace - returns all jobs within either a) the user's namespace or b) the Shared namespace.
 * These are the jobs which should be visible to the user.
 * @param rows the jobs being filtered
 * @param user the current user
 */
export const filterJobsForUser = (rows: SheetSyncJob[], user: User) => rows
  .filter(({ userEmail, shared }) => userEmail === user.email || shared);

/**
 * Given a table id in format `project.dataset.table`, returns the last two identifiers (`dataset.table`).
 * @param tableId the full table id being shortened
 */
export const getShortenedTableId = (tableId: string) => tableId.split('.').slice(-2).join('.');

/**
 * Extracts the workbook Id given a url.  If the url is not a valid Google Sheets workbook url, returns an empty string.
 * @param url url being parsed for workbook id
 */
export const getWorkbookIdFromUrl = (url: string) => {
  const googleSheetSubstring = 'https://docs.google.com/spreadsheets/d/';
  const urlIsValid = url.startsWith(googleSheetSubstring);
  if (!urlIsValid) return '';
  const regex = new RegExp(`${googleSheetSubstring}([a-zA-Z0-9_-]+)`);
  const match = url.match(regex);
  // the second item in the array will be the found workbookId - if nonexistent, return empty string
  return match?.[1] || '';
};

/**
 * Validates whether job name already exists in user's viewable jobs (i.e. in own or shared namespace).
 * @param jobs jobs viewable to user
 * @param jobName new job name
 * @param syncJob existing job (if a user is editing the job in the manageJobModal)
 */
export const doesJobNameAlreadyExist = (
  jobs: SheetSyncJob[],
  jobName: string,
  syncJob: SheetSyncJob | undefined,
) => !!jobs
// filter against existing syncJob if that's being passed.  if we are editing a job, we don't want to compare against itself.
  .filter((job) => job.jobId !== syncJob?.jobId)
  .find((job) => job.jobName === jobName);

/**
 * Validates whether job fields are unchanged when editing a job in the ManageJobModal.  Returns true if all relevant
 * job fields are unchanged.
 * @param syncJob new job (edits to the existing job)
 * @param frequencyCron edited frequency cron (essentially part of syncJob)
 * @param existingSyncJob existing job
 */
export const areJobFieldsUnchanged = (
  syncJob: JobValues,
  existingSyncJob: SheetSyncJob | undefined,
  frequencyCron: string,
) => {
  // if there is no existing sync job then by definition the fields are not unchanged since there are no fields to compare
  if (!existingSyncJob) return false;
  const { overwriteOnSync, comments, jobName } = syncJob;
  const { refreshCron, writeMethod } = existingSyncJob;
  // check values for equality between existing job and new edits (syncJob)
  const frequencyUnchanged = frequencyCron === refreshCron;
  const writeMethodUnchanged = (overwriteOnSync
    ? WriteDisposition.OVERWRITE : WriteDisposition.APPEND) === writeMethod;
  const commentsUnchanged = existingSyncJob.comments === comments;
  const jobNameUnchanged = existingSyncJob.jobName === jobName;
  return frequencyUnchanged && writeMethodUnchanged && jobNameUnchanged && commentsUnchanged;
};

/**
 * Encode frequency selections by user into frequency type/freq detail for storage in database.
 * @param selectedFrequency job frequency
 * @param selectedDayOfWeek job frequency day of week (if weekly job)
 * @param selectedDayOfMonth job frequency day of month (if monthly job)
 */
export const getFrequencyFields = (
  selectedFrequency: FrequencyOptions,
  selectedDayOfWeek: number,
  selectedDayOfMonth: number,
) => {
  // maps freq type to the value of the second freq selector. e.g. for weekly syncs, the second selector would need to be
  // the day of the week
  const frequencyDetailMap = {
    [FrequencyOptions.WEEKLY]: selectedDayOfWeek,
    [FrequencyOptions.MONTHLY]: selectedDayOfMonth,
    [FrequencyOptions.DAILY]: null,
    [FrequencyOptions.HOURLY]: null,
    [FrequencyOptions.MANUAL]: null,
  };

  return { displaySyncFreqType: selectedFrequency, displaySyncFreqDetail: frequencyDetailMap[selectedFrequency] };
};

/**
 * Generates the request payload for either of the job modification api calls - Edit Job or Create Job.
 * @param syncJob the created or edited job values
 * @param frequencyCron the created or edited frequency cron
 * @param userEmail? the user email (passed when job is being created)
 * @param workbookId? the workbook Id (passed when job is being created)
 */
export const generateModifyJobPayload = function<T> (
  syncJob: JobValues,
  frequencyCron: string,
  userEmail?: string,
  workbookId?: string,
): T {
  const {
    selectedFrequency, workbookUrl, overwriteOnSync, selectedSheet, shared,
    identifierColumn, comments, jobName, tableName, selectedDayOfWeek, selectedDayOfMonth,
  } = syncJob;

  const {
    displaySyncFreqType, displaySyncFreqDetail,
  } = getFrequencyFields(selectedFrequency, selectedDayOfWeek, selectedDayOfMonth);

  const editPayload: EditJobReq = {
    overwriteOnSync,
    identifierColumn,
    frequencyCron,
    displaySyncFreqType,
    displaySyncFreqDetail,
    comments,
    jobName,
  };

  // if both a user email and workbook Id have been passed, return the createJob payload
  if (userEmail && workbookId) {
    return {
      ...editPayload,
      workbookId,
      selectedSheet,
      tableName,
      userEmail,
      shared,
      workbookUrl,
    } as T;
  }
  // if there is no user email or workbookId passed, return the truncated edit payload
  return editPayload as T;
};

// all sync schedules run at the same hour (2am) to minimize likelihood of updating during an edit
export const syncDefaultFrequencyHour = 2;
// formatted into readable string e.g. 2am
export const frequencyHourFormatted = dayjs().hour(syncDefaultFrequencyHour).minute(0).format('ha');

/**
 * Calculates cron string for sync frequency of a job given the sync frequency data.
 * @param selectedFrequency selected frequency (hourly, daily, weekly, monthly)
 * @param selectedDayOfMonth the selected day of week (used if the schedule is weekly)
 * @param selectedDayOfWeek the selected day of month (used if the schedule is monthly)
 */
export const calculateSyncCron = (
  selectedFrequency: FrequencyOptions,
  selectedDayOfMonth: number,
  selectedDayOfWeek: number,
) => {
  const defaultMinute = 0;
  const any = '*';
  const cron: (string | number)[] = [defaultMinute];
  switch (selectedFrequency) {
    case FrequencyOptions.HOURLY:
      cron.push(any, any, any, any);
      break;
    case FrequencyOptions.DAILY:
      cron.push(syncDefaultFrequencyHour, any, any, any);
      break;
    case FrequencyOptions.WEEKLY:
      cron.push(syncDefaultFrequencyHour, any, any, selectedDayOfWeek);
      break;
    case FrequencyOptions.MONTHLY:
      cron.push(syncDefaultFrequencyHour, selectedDayOfMonth, any, any);
      break;
    default:
      return '';
  }
  return cron.join(' ');
};

/**
 * Extracts headers from preview rows returned by backend.  Empty cells in a row object are represented as a missing
 * key - e.g. if there is a column named "Amount", if a row has an empty value for "Amount" the "Amount" key will be
 * omitted from the row object entirely.  This means that we have to iterate over all rows to get the maximum/most accurate
 * number of headers.
 * @param rows preview rows of Google Sheet
 */
export const getHeadersFromPreviewRows = (
  rows: SheetRow[],
) => Array.from(new Set(rows.map((row) => Object.keys(row)).flat()));

/**
 * Watches jobRunHistory collection in Firestore and updates state in real-time whenever data updates.
 * @param setJobRunHistory sets jobRunHistory state
 */
export const watchJobRunHistory = (setJobRunHistory: (data: JobRunHistory[]) => void) => {
  const qu = query(collection(db, 'jobRunHistory'));
  return onSnapshot(qu, (((snapshot) => {
    const docCollection: DocumentData[] = [];
    snapshot.forEach((single_doc) => {
      docCollection.push(single_doc.data());
    });
    setJobRunHistory(docCollection as JobRunHistory[]);
  })));
};

/**
 * Given the run history of all jobs and a jobId, return the most recent run data for that given job Id.
 * @param jobRunHistory history of all job runs
 * @param relevantJobId id of the job for which we are getting the most recent run
 */
export const getLastRun = (jobRunHistory: JobRunHistory[], relevantJobId: string) => {
  const relevantHistory = jobRunHistory.find(({ jobId }) => jobId === relevantJobId);
  // there is no last run if there is no run history document for this job OR if the document exists but contains no entries under the History field
  if (!relevantHistory || !relevantHistory.history.length) return null;
  // we sort this in reverse order so that the most recent run is the first in the list
  const sortedRuns = relevantHistory.history.sort(
    (a, b) => b.runTimestamp.seconds - a.runTimestamp.seconds,
  );
  // after sorting and length validation, the run at index 0 will be the most recent
  return sortedRuns[0];
};

// Text displayed in last run status/timestamp if a job has yet to run for the first time
export const lastJobRunNotFoundText = "Hasn't run yet";

/**
 * Given all job histories and a job id, retrieve the last run timestamp of a given job.
 * @param jobRunHistory all jobs history
 * @param jobId id of job to retrieve last run data for
 */
export const getLastRunTimestamp = (jobRunHistory: JobRunHistory[], jobId: string) => {
  const lastRun = getLastRun(jobRunHistory, jobId);
  if (!lastRun) return lastJobRunNotFoundText;
  return dayjs.unix(lastRun.runTimestamp.seconds).format('M/DD/YY h:mma');
};
