import { GlideToBQJob } from './glide-to-bq/types';
import { BQToGlideJob } from './bq-to-glide/types';

/**
 * Determines whether job name is in conflict with another job name.
 * @param jobName the current name of the job in the user input
 * @param jobs all jobs
 * @param jobBeingEditedName the name of the job being edited (undefined if job is being created)
 */
export const jobNameInConflict = (
  jobName: string,
  jobs: (GlideToBQJob | BQToGlideJob)[],
  jobBeingEditedName: string | undefined,
) => {
  const jobNames = jobs.map((job) => job.jobName);
  const jobExistsWithSameName = jobNames.includes(jobName);
  return jobExistsWithSameName && (!jobBeingEditedName || jobBeingEditedName !== jobName);
};
