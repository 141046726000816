import React, { useEffect, useState } from 'react';
import {
  Autocomplete, Dialog, IconButton, TextField,
} from '@mui/material';
import { AxiosResponse } from 'axios';
import { LoadingButton } from '@mui/lab';
import { v4 as uuid } from 'uuid';
import { Clear } from '@mui/icons-material';
import {
  createGlideToBQJob, editGlideToBQJob,
  getGlideDatasetTablesBQ,
  getGlidePreviewRows,
} from '../../../../api/endpoints-api/EndpointsAPI';
import { bqTableNameRegex } from '../../google-sheet-sync/manage-job-modal/TableNameInput';
import { PreviewRows } from '../../google-sheet-sync/manage-job-modal/PreviewRows';
import {
  FrequencyOptions, SheetRow, TableGroupDoc, TableGroupMapping,
} from '../../landing-page-types';
import { useUser } from '../../../../api';
import {
  CreateGlideToBQJobPayload, EditGlideToBQJobPayload, GlideApp, GlideTable, GlideToBQJob,
} from './types';
import { SyncFrequencyOptions } from '../../google-sheet-sync/manage-job-modal/SyncFrequencyOptions';
import {
  calculateSyncCron,
  defaultDayOfMonth,
  defaultDayOfWeek,
  defaultFrequency, getFrequencyFields, decodeFrequencyDetails,
} from '../../../../api/landing-page-api/LandingPageAPI';
import { useAlert } from '../../../../api/alert-api/AlertAPI';
import { DependentGroupSelector } from '../../common/DependentGroupSelector';
import { updateTableGroup } from '../../common/helpers';
import { jobNameInConflict } from '../helpers';

// Params for ManageGlideToBQJobModal component
interface ManageGlideToBQJobModalParams {
  modalOpen: boolean,
  closeModal: ()=> void
  jobs: GlideToBQJob[]
  glideApps: GlideApp[]
  jobBeingEdited?: GlideToBQJob
  groups: TableGroupDoc[]
  groupMappings: TableGroupMapping[]
}

/**
 * Generate the initial form values for the modal (based on whether the job is being edited or created).
 * @param jobBeingEdited job being edited (if there is one - if not, job is being created)
 * @param glideApps all glide apps
 * @param groupMappings job group mappings
 */
const generateInitValues = (
  jobBeingEdited: GlideToBQJob | undefined,
  glideApps: GlideApp[],
  groupMappings: TableGroupMapping[],
) => {
  if (jobBeingEdited) {
    // decode frequency values
    const { displaySyncFreqType, displaySyncFreqDetail } = jobBeingEdited;
    const {
      selectedDayOfMonth, selectedDayOfWeek,
    } = decodeFrequencyDetails(displaySyncFreqDetail, displaySyncFreqType);

    // retrieve the Glide app and table from their respective IDs
    const selectedApp = glideApps.find(({ appId }) => appId === jobBeingEdited.glideAppId);
    if (!selectedApp) throw new Error('Glide app not found');
    const selectedTable = selectedApp.tables.find((table) => table.id === jobBeingEdited.glideTableId);
    if (!selectedTable) throw new Error('Glide table not found');

    // get job group (if there is one)
    const selectedGroup = groupMappings.find(({ tableId }) => tableId === jobBeingEdited.jobId)?.groupId || '';

    return {
      selectedApp,
      selectedTable,
      selectedFrequency: jobBeingEdited.displaySyncFreqType,
      selectedDayOfWeek,
      selectedDayOfMonth,
      jobName: jobBeingEdited.jobName,
      bqTableName: jobBeingEdited.tableName,
      comments: jobBeingEdited.comments,
      selectedGroup,
    };
  }
  // if new job, return default values
  return {
    selectedApp: null,
    selectedTable: null,
    selectedFrequency: defaultFrequency,
    selectedDayOfWeek: defaultDayOfWeek,
    selectedDayOfMonth: defaultDayOfMonth,
    jobName: '',
    bqTableName: '',
    comments: '',
    selectedGroup: '',
  };
};

/**
 * Create or edit a Glide to BQ job.
 * @param modalOpen whether the modal is open
 * @param closeModal function to close modal
 * @param jobs all jobs
 * @param glideApps all glide apps
 * @param jobBeingEdited the job being edited
 * @param groups job groups
 * @param groupMappings job group mappings
 * @constructor
 */
export const ManageGlideToBQJobModal = function ({
  modalOpen, closeModal, jobs, glideApps, jobBeingEdited, groups, groupMappings,
}: ManageGlideToBQJobModalParams) {
  // get initial values of the modal form - this is based on whether a job is being created or edited
  const initValues = generateInitValues(jobBeingEdited, glideApps, groupMappings);

  // form/job values
  const [selectedApp, setSelectedApp] = useState<GlideApp | null>(initValues.selectedApp);
  const [selectedTable, setSelectedTable] = useState<GlideTable | null>(initValues.selectedTable);
  const [jobName, setJobName] = useState<string>(initValues.jobName);
  const [bqTableName, setBqTableName] = useState<string>(initValues.bqTableName);
  const [comments, setComments] = useState<string>(initValues.comments);
  const [selectedDayOfWeek, setSelectedDayOfWeek] = useState<number>(initValues.selectedDayOfWeek);
  const [selectedDayOfMonth, setSelectedDayOfMonth] = useState<number>(initValues.selectedDayOfMonth);
  const [selectedFrequency, setSelectedFrequency] = useState<FrequencyOptions>(initValues.selectedFrequency);
  const [selectedGroup, setSelectedGroup] = useState<string>(initValues.selectedGroup);

  const [previewRows, setPreviewRows] = useState<SheetRow[]>([]);
  const [previewLoading, setPreviewLoading] = useState<boolean>(false);
  // get existing tables in Glide dataset (in BQ)
  const [existingBqTables, setExistingBqTables] = useState<string[]>([]);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);

  const updateSelectedGroup = (group: string) => setSelectedGroup(group);

  const user = useUser();
  const alert = useAlert();

  /**
   * Get all existing tables in the Glide dataset in BigQuery.
   */
  useEffect(() => {
    getGlideDatasetTablesBQ()
      .then((res: AxiosResponse<string[]>) => setExistingBqTables(res.data))
      .catch(() => alert?.alert('Failed to retrieve BigQuery tables', 'error'));
  }, []);

  // if the app is changed, null out the selected table
  useEffect(() => {
    // this causes a bug if we leave it on in the Edit modal, so we only do this in the Create modal
    if (!jobBeingEdited) setSelectedTable(null);
  }, [selectedApp]);

  /**
   * Get Glide table preview rows once user has selected a Glide table.
   */
  useEffect(() => {
    if (!selectedTable || !selectedApp) {
      setPreviewRows([]);
      return;
    }
    setPreviewLoading(true);
    getGlidePreviewRows({ appId: selectedApp.appId, tableId: selectedTable.id })
      .then((res: AxiosResponse<SheetRow[]>) => setPreviewRows(res.data))
      .catch(() => alert?.alert('Failed to retrieve preview rows', 'error'))
      .finally(() => setPreviewLoading(false));
  }, [selectedTable]);

  // encoded frequency values for updating/creating job
  const {
    displaySyncFreqType, displaySyncFreqDetail,
  } = getFrequencyFields(selectedFrequency, selectedDayOfWeek, selectedDayOfMonth);

  // refresh cron expression calculated from frequency values
  const refreshCron = calculateSyncCron(selectedFrequency, selectedDayOfMonth, selectedDayOfWeek);

  /**
   * Edit a given job.
   */
  const editJob = () => {
    if (!jobBeingEdited) return;
    setLoadingSubmit(true);
    const payload: EditGlideToBQJobPayload = {
      jobId: jobBeingEdited.jobId,
      jobName,
      comments,
      displaySyncFreqType,
      displaySyncFreqDetail,
      refreshCron,
    };
    editGlideToBQJob(payload)
      .then(async () => {
        alert?.alert('Successfully edited job', 'success');
        closeModal();
        await updateTableGroup(selectedGroup, jobBeingEdited.jobId, groupMappings);
      })
      .catch(() => alert?.alert('Failed to edit job', 'error'))
      .finally(() => setLoadingSubmit(false));
  };

  /**
   * Create the given job.
   */
  const createJob = () => {
    if (!user?.email || !selectedTable || !selectedApp) return;
    setLoadingSubmit(true);
    const jobId = uuid();
    const payload: CreateGlideToBQJobPayload = {
      jobId,
      jobName,
      comments,
      displaySyncFreqType,
      displaySyncFreqDetail,
      refreshCron,
      tableName: bqTableName,
      glideAppId: selectedApp.appId,
      glideTableId: selectedTable.id,
      userEmail: user.email,
    };
    createGlideToBQJob(payload)
      .then(async () => {
        closeModal();
        alert?.alert('Successfully created job', 'success');
        await updateTableGroup(selectedGroup, jobId, groupMappings);
      })
      .catch(() => alert?.alert('Failed to create job', 'error'))
      .finally(() => setLoadingSubmit(false));
  };

  // whether the BQ table name input by the user is already in use
  const bqTableNameConflict = existingBqTables.includes(bqTableName) && !jobBeingEdited;

  // whether the user-inputted job name conflicts with another job name
  const jobNameConflict = jobNameInConflict(jobName, jobs, jobBeingEdited?.jobName);

  const missingValues = !jobName || !bqTableName || !selectedApp || !selectedTable;
  const disableSubmit = jobNameConflict || bqTableNameConflict || missingValues;

  return (
    <Dialog
      open={modalOpen}
      onClose={closeModal}
      className="w-[100vw] bg-transparent"
      maxWidth="xl"
    >
      <div className="relative z-40 w-[80vw] h-[90vh] bg-white rounded-lg flex flex-col items-center">
        {/* Close modal button */}
        <div className="flex flex-row w-full justify-end">
          <IconButton onClick={closeModal}>
            <Clear />
          </IconButton>
        </div>
        {/* Header */}
        <div className="mb-10 text-xl font-medium">{jobBeingEdited ? 'Edit Job' : 'Add New Job'}</div>
        <div className="flex flex-row justify-evenly w-full">
          <div className="flex flex-col w-1/3 gap-y-14">
            {/* Glide App selector */}
            <Autocomplete
              options={glideApps}
              value={selectedApp}
              onChange={(e, value) => setSelectedApp(value)}
              getOptionLabel={({ appName }) => appName}
              renderInput={(params) => <TextField {...params} label="Glide App" />}
              disabled={!!jobBeingEdited}
            />
            {/* Glide table selector */}
            <Autocomplete
              options={selectedApp?.tables || []}
              value={selectedTable}
              onChange={(e, value) => setSelectedTable(value)}
              getOptionLabel={({ name }) => name}
              renderInput={(params) => <TextField {...params} label="Glide Table" />}
              disabled={!!jobBeingEdited}
              getOptionDisabled={({ disabled }) => disabled}
            />
            {/* Job name input */}
            <TextField
              label="Job Name"
              variant="outlined"
              value={jobName}
              helperText={jobNameConflict && <div className="text-red-500">Already exists a job with that name</div>}
              onChange={(e) => setJobName(e.target.value)}
            />
            {/* Job Group selector */}
            <DependentGroupSelector
              groups={groups}
              selectedGroup={selectedGroup}
              setSelectedGroup={updateSelectedGroup}
            />
            {/* BigQuery table name input */}
            <TextField
              label="BigQuery table name"
              variant="outlined"
              value={bqTableName}
              helperText={
              bqTableNameConflict
                && <div className="text-red-500">Already exists a BigQuery table with that name in the dataset</div>
              }
              onChange={(e) => {
                // validate that the table name passes regex validation
                const { value } = e.target;
                if (bqTableNameRegex.test(value)) setBqTableName(value);
              }}
              disabled={!!jobBeingEdited}
            />
          </div>
          <div className="flex flex-col w-1/3 gap-y-2">
            {/* Glide table preview rows */}
            <PreviewRows
              rows={previewRows}
              loadingSheetData={previewLoading}
              userDoesNotHaveSheetAccess={false}
            />
            {/* Sync frequency selector */}
            <SyncFrequencyOptions
              selectedDayOfWeek={selectedDayOfWeek}
              selectedDayOfMonth={selectedDayOfMonth}
              selectedFrequency={selectedFrequency}
              updateSelectedDayOfMonth={setSelectedDayOfMonth}
              updateSelectedDayOfWeek={setSelectedDayOfWeek}
              updateSelectedFrequency={setSelectedFrequency}
              loadingSheetData={false}
            />
            {/* Comments input */}
            <TextField
              label="Comments"
              multiline
              rows={5}
              variant="outlined"
              value={comments}
              onChange={({ target }) => setComments(target.value)}
            />
            {/* Submit button */}
            <LoadingButton
              variant="contained"
              className="bg-blue-500"
              disabled={disableSubmit}
              onClick={jobBeingEdited ? editJob : createJob}
              loading={loadingSubmit}
            >
              Submit job
            </LoadingButton>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
