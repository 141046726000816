import React, { useEffect, useMemo, useState } from 'react';
import { Dialog, IconButton } from '@mui/material';
import { AxiosError, AxiosResponse } from 'axios';
import { Clear } from '@mui/icons-material';
import { useUser } from '../../../../api';
import {
  generateModifyJobPayload,
  doesJobNameAlreadyExist,
  getJobModalInitValues,
  getWorkbookIdFromUrl,
  calculateSyncCron,
} from '../../../../api/landing-page-api/LandingPageAPI';
import {
  ApiError, ApiErrorCode, CreateSyncJobReq, EditJobReq, FrequencyOptions, JobValues, SheetRow,
} from '../../landing-page-types';
import { PreviewRows } from './PreviewRows';
import { SyncFrequencyOptions } from './SyncFrequencyOptions';
import { CreateNewJobModalParams } from '../../landing-page-params';
import { alertError } from '../../../../api/error-api/ErrorAPI';
import { useAlert } from '../../../../api/alert-api/AlertAPI';
import { WorkbookUrlInput } from './WorkbookUrlInput';
import { SheetSelector } from './SheetSelector';
import { JobNameInput } from './JobNameInput';
import { TableNameInput } from './TableNameInput';
import { SharedNamespaceCheckbox } from './SharedNamespaceCheckbox';
import { WriteDispositionSelect } from './WriteDispositionSelect';
import { CommentsInput } from './CommentsInput';
import { SubmitButton } from './SubmitButton';
import {
  createSyncJob, editSyncJob, getPreviewRows, getWorkbookSheets,
} from '../../../../api/endpoints-api/EndpointsAPI';
import { DependentGroupSelector } from '../../common/DependentGroupSelector';
import { updateTableGroup } from '../../common/helpers';

export const ManageJobModal = function ({
  modalOpen, closeModal, existingSyncJob, jobs, groups, groupMappings,
}: CreateNewJobModalParams) {
  const initJobValues = getJobModalInitValues(groupMappings, existingSyncJob);
  // holds the values of the job that we are creating/editing
  const [syncJob, setSyncJob] = useState<JobValues>(initJobValues);
  // sheets that exist within the selected workbook
  const [sheetOptions, setSheetOptions] = useState<string[]>([]);
  // first five rows of selected sheet
  const [previewRows, setPreviewRows] = useState<SheetRow[]>([]);
  // cron schedule for sync frequency
  const [frequencyCron, setFrequencyCron] = useState<string>('');
  // stores the api error message if one is returned when editing/creating a modal
  const [apiError, setApiError] = useState<ApiError | null>(null);
  // loading state onSubmit
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  // loading state when pulling data of a sheet in workbook
  const [loadingSheetData, setLoadingSheetData] = useState<boolean>(false);
  // loading state when pulling a workbook to get its sheets
  const [loadingSheetOptions, setLoadingSheetOptions] = useState<boolean>(false);
  const [selectedGroup, setSelectedGroup] = useState<string>(initJobValues.selectedGroup);

  const updateSelectedGroup = (group: string) => setSelectedGroup(group);

  const {
    workbookUrl, selectedSheet, selectedDayOfWeek, selectedDayOfMonth, jobName, tableName, selectedFrequency,
  } = syncJob;

  const updateSyncJob = (job: JobValues) => setSyncJob(job);

  const user = useUser();
  const alert = useAlert();

  const userHasEmail = user && user.email;
  // extracts workbookId from current url - if url is not valid, returns empty string
  const workbookId = useMemo(() => getWorkbookIdFromUrl(workbookUrl), [workbookUrl]);
  // with shared jobs, it is possible for a user to have access to a job but not the underlying sheet
  const userDoesNotHaveSheetAccess = apiError?.errorCode === ApiErrorCode.SHEET_PERMISSIONS_INVALID;

  const clearIdentifierColumn = () => setSyncJob({ ...syncJob, identifierColumn: '' });

  // runs when successfully edited or created job
  const handleJobOperationSuccess = async (successText: string, jobId: string) => {
    closeModal();
    alert?.alert(successText, 'success');
    await updateTableGroup(selectedGroup, jobId, groupMappings);
  };

  const handleCreateJobFailure = (err: AxiosError<ApiError, ApiError>) => {
    if (err.response) setApiError(err.response.data);
    throw alertError(alert, 'Failed to create sync job', err);
  };

  const createJob = () => {
    if (!userHasEmail || !selectedSheet || !tableName || !jobName || !workbookId) return;
    setLoadingSubmit(true);
    const payload = generateModifyJobPayload<CreateSyncJobReq>(syncJob, frequencyCron, user.email, workbookId);
    createSyncJob(payload)
      .then((result: AxiosResponse<string>) => handleJobOperationSuccess('Created job successfully', result.data))
      .catch((err: AxiosError<ApiError, ApiError>) => handleCreateJobFailure(err))
      .finally(() => setLoadingSubmit(false));
  };

  const editJob = () => {
    if (!existingSyncJob || !jobName) return;
    setLoadingSubmit(true);
    const payload = generateModifyJobPayload<EditJobReq>(syncJob, frequencyCron);
    editSyncJob(existingSyncJob.jobId, payload)
      .then(() => handleJobOperationSuccess('Successfully edited job', existingSyncJob.jobId))
      .catch((err) => { throw alertError(alert, 'Failed to edit sync job', err); })
      .finally(() => setLoadingSubmit(false));
  };

  // regenerates the cron string whenever one of the constituent states changes
  useEffect(() => {
    const cron = calculateSyncCron(selectedFrequency, selectedDayOfMonth, selectedDayOfWeek);
    setFrequencyCron(cron);
  }, [selectedFrequency, selectedDayOfWeek, selectedDayOfMonth]);

  // retrieves preview rows of a given Google Sheet when sheet is selected/changes
  useEffect(() => {
    if (!selectedSheet || !userHasEmail || !workbookId) return;
    setLoadingSheetData(true);
    // must clear identifier column on sheet change so that it doesn't persist when changing sheets. only applicable to create mode
    if (!existingSyncJob) clearIdentifierColumn();
    getPreviewRows(workbookId, user.email, selectedSheet)
      .then(({ data }: AxiosResponse<SheetRow[]>) => setPreviewRows(data))
      .catch((err: AxiosError<ApiError, ApiError>) => {
        const { response } = err;
        if (response && response.data.errorCode === ApiErrorCode.SHEET_PERMISSIONS_INVALID) setApiError(response.data);
        else { throw alertError(alert, 'Failed to pull preview rows from selected sheet', err); }
      })
      .finally(() => setLoadingSheetData(false));
  }, [selectedSheet]);

  // retrieves all sheet names of a given Google Sheets Workbook when workbook url/ID is set or changes
  useEffect(() => {
    if (!userHasEmail || !workbookId) return;
    // no need to make the call to get sheet options if we are editing a job since we already have an immutable selected sheet
    if (existingSyncJob) {
      setSheetOptions([selectedSheet]);
      return;
    }
    setLoadingSheetOptions(true);
    getWorkbookSheets(workbookId, user.email)
      .then(({ data }: AxiosResponse<string[]>) => setSheetOptions(data))
      .catch((err) => { throw alertError(alert, 'Failed to pull sheets from workbook url', err); })
      .finally(() => setLoadingSheetOptions(false));
  }, [workbookId]);

  // whether or not job name is unique within viewable jobs.  this is used for validation when editing/creating jobs.
  const jobNameIsNotUnique = useMemo(
    () => doesJobNameAlreadyExist(jobs, jobName, existingSyncJob),
    [jobs, jobName, existingSyncJob],
  );
  const modalHeaderText = existingSyncJob ? 'Edit Job' : 'Add New Job';
  // the function of the submit button is to editJob if there is an existing job being passed in, otherwise it createsJob
  const handleSubmit = existingSyncJob ? editJob : createJob;

  // true if a user is editing a shared job where they do not have access to the underlying sheet
  const editingSharedJobWithoutSheetAccess = userDoesNotHaveSheetAccess && !!existingSyncJob;
  const sheetOptionsRetrieved = sheetOptions.length > 0;
  const sheetDataRetrieved = previewRows.length > 0 || editingSharedJobWithoutSheetAccess;

  const displaySheetSelect = loadingSheetOptions || sheetOptionsRetrieved;
  const displayPreviewRows = loadingSheetData || sheetDataRetrieved;
  const displayAllSelectors = (sheetDataRetrieved && sheetOptionsRetrieved) || loadingSheetData;

  const groupUnchanged = (selectedGroup === initJobValues.selectedGroup) && !!existingSyncJob;

  return (
    <Dialog
      open={modalOpen}
      onClose={closeModal}
      className="w-[100vw] bg-transparent"
      maxWidth="xl"
    >
      {/* Modal itself  */}
      <div className="relative z-40 w-full bg-white rounded-lg py-4 flex flex-col
        items-center justify-center"
      >
        {/* Close modal button */}
        <IconButton className="absolute right-4 top-4" onClick={closeModal}>
          <Clear />
        </IconButton>
        {/* Content container */}
        <div className="mx-auto p-4 w-[70vw] h-[85vh] flex flex-col px-8 pt-4 font-inter">
          <div className="mx-auto text-xl font-medium">{modalHeaderText}</div>
          <div className="flex flex-row mt-10">
            <div className="flex flex-col w-96">
              {/* Workbook url textfield  */}
              <WorkbookUrlInput
                syncJob={syncJob}
                updateSyncJob={updateSyncJob}
                existingSyncJob={existingSyncJob}
                sheetOptionsRetrieved={sheetOptionsRetrieved}
              />
              {/* Sheet selector */}
              {displaySheetSelect && (
              <SheetSelector
                syncJob={syncJob}
                updateSyncJob={updateSyncJob}
                sheetOptions={sheetOptions}
                existingSyncJob={existingSyncJob}
                apiError={apiError}
                loadingSheetOptions={loadingSheetOptions}
              />
              )}
              {displayAllSelectors && (
                <>
                  {/* Job name */}
                  <JobNameInput
                    syncJob={syncJob}
                    updateSyncJob={updateSyncJob}
                    jobNameIsNotUnique={jobNameIsNotUnique}
                    loadingSheetData={loadingSheetData}
                  />
                  <DependentGroupSelector
                    groups={groups}
                    selectedGroup={selectedGroup}
                    setSelectedGroup={updateSelectedGroup}
                  />
                  {/* Table name to write to */}
                  <TableNameInput
                    syncJob={syncJob}
                    apiError={apiError}
                    existingSyncJob={existingSyncJob}
                    updateSyncJob={updateSyncJob}
                    loadingSheetData={loadingSheetData}
                  />
                  {/* Shared namespace checkbox */}
                  <SharedNamespaceCheckbox
                    syncJob={syncJob}
                    existingSyncJob={existingSyncJob}
                    updateSyncJob={updateSyncJob}
                    loadingSheetData={loadingSheetData}
                  />
                  {/* Write disposition on sync toggle */}
                  <WriteDispositionSelect
                    syncJob={syncJob}
                    previewRows={previewRows}
                    existingSyncJob={existingSyncJob}
                    updateSyncJob={updateSyncJob}
                    loadingSheetData={loadingSheetData}
                    userDoesNotHaveSheetAccess={userDoesNotHaveSheetAccess}
                  />
                </>
              )}
            </div>
            <div className="flex flex-col w-1/2 mx-auto">
              {/* Preview rows from Google Sheet */}
              {displayPreviewRows && (
              <PreviewRows
                rows={previewRows}
                loadingSheetData={loadingSheetData}
                userDoesNotHaveSheetAccess={userDoesNotHaveSheetAccess}
              />
              )}
              {displayAllSelectors && (
              <>
                {/* Select sync frequency */}
                <SyncFrequencyOptions
                  selectedDayOfWeek={syncJob.selectedDayOfWeek}
                  selectedDayOfMonth={syncJob.selectedDayOfMonth}
                  selectedFrequency={syncJob.selectedFrequency}
                  updateSelectedDayOfMonth={
                  (dayOfMonth: number) => setSyncJob({ ...syncJob, selectedDayOfMonth: dayOfMonth })
                }
                  updateSelectedDayOfWeek={
                  (dayOfWeek: number) => setSyncJob({ ...syncJob, selectedDayOfWeek: dayOfWeek })
                }
                  updateSelectedFrequency={
                  (frequency: FrequencyOptions) => setSyncJob({ ...syncJob, selectedFrequency: frequency })
                }
                  loadingSheetData={loadingSheetData}
                />
                {/* Comments textfield */}
                <CommentsInput
                  syncJob={syncJob}
                  updateSyncJob={updateSyncJob}
                  loadingSheetData={loadingSheetData}
                />
              </>
              )}
            </div>
          </div>
          {/* Submit edit/create job button */}
          <SubmitButton
            existingSyncJob={existingSyncJob}
            syncJob={syncJob}
            loadingSubmit={loadingSubmit}
            frequencyCron={frequencyCron}
            jobNameIsNotUnique={jobNameIsNotUnique}
            handleSubmit={handleSubmit}
            groupUnchanged={groupUnchanged}
          />
        </div>
      </div>
    </Dialog>
  );
};
