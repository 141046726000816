import React from 'react';
import {
  FormControl, InputLabel, MenuItem, Select,
} from '@mui/material';
import { TableGroupDoc } from '../landing-page-types';

// Params for DependentGroupSelector component
interface DependentGroupSelectorParams {
  groups: TableGroupDoc[],
  selectedGroup: string,
  setSelectedGroup: (group: string) => void
}

/**
 * Selector component which allows user to change the given group of a table.  Must have state and state updater passed
 * into component.
 * @param tableId id of the table or job that we are updating
 * @param groups all groups
 * @constructor
 */
export const DependentGroupSelector = function ({
  groups,
  selectedGroup,
  setSelectedGroup,
}: DependentGroupSelectorParams) {
  return (
    <FormControl fullWidth>
      <InputLabel>Job Group</InputLabel>
      <Select
        value={selectedGroup}
        label="Job Group"
        onChange={(e) => setSelectedGroup(e.target.value)}
        variant="standard"
      >
        <MenuItem className="italic" value="">None</MenuItem>
        {groups.map((group) => <MenuItem value={group.id}>{group.name}</MenuItem>)}
      </Select>
    </FormControl>
  );
};
