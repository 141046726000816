import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { Box, IconButton } from '@mui/material';
import React from 'react';
import { Delete, Edit } from '@mui/icons-material';
import { JobRunHistory, UpdateGroupInfo } from '../../landing-page-types';
import { BQToGlideJob } from './types';
import { getLastRunTimestamp, getShortenedTableId } from '../../../../api/landing-page-api/LandingPageAPI';
import { StandaloneGroupSelector } from '../../common/StandaloneGroupSelector';
import { generateBigQueryURL, getSyncSchedule } from '../../common/helpers';
import { RunStatusChip } from '../../common/RunStatusChip';
import { ManualSyncButton } from '../../common/ManualSyncButton';

/**
 * Core non-interactive columns for BQ to Glide Datagrid.
 * These columns are shown in the job deletion modal.
 */
export const coreBQToGlideColumns: GridColDef<BQToGlideJob>[] = [
  {
    field: 'jobName',
    headerName: 'Job Name',
    flex: 1,
  },
  {
    field: 'Sync Schedule',
    flex: 1,
    valueGetter: ({ row }) => getSyncSchedule(row.refreshCron),
  },
  {
    field: 'userEmail',
    flex: 1,
    headerName: 'Owner',
  },
  {
    field: 'tableId',
    headerName: 'Table ID',
    flex: 1,
    renderCell: ({ row }) => {
      const [project, dataset, table] = row.tableId.split('.');
      const link = generateBigQueryURL(project, dataset, table);
      return (
        <a
          href={link}
          target="_blank"
          className="text-blue-500 underline"
          rel="noreferrer"
        >
          {row.tableId}
        </a>
      );
    },
  },
  {
    field: 'glideTableId',
    headerName: 'Glide Table',
    flex: 1,
    valueGetter: ({ row }) => row.glideTableName,
  },
];

/**
 * Columns for BQ to Glide Datagrid table
 * @param updateGroupInfo info to update group
 * @param updateJobBeingEdited function to update the job being edited
 * @param updateJobBeingDeleted function to update the job being deleted
 * @param jobRunHistory all job history
 */
const getColumns = (
  updateGroupInfo: UpdateGroupInfo,
  updateJobBeingEdited: (job: BQToGlideJob | null) => void,
  updateJobBeingDeleted: (job: BQToGlideJob | null) => void,
  jobRunHistory: JobRunHistory[],
): GridColDef<BQToGlideJob>[] => {
  const interactiveColumns: GridColDef<BQToGlideJob>[] = [
    {
      field: 'lastRunStatus',
      headerName: 'Last Run Status',
      flex: 1,
      renderCell: ({ row }) => <RunStatusChip jobId={row.jobId} jobRunHistory={jobRunHistory} />,
    },
    {
      field: 'lastRunTimestamp',
      headerName: 'Last Run Time',
      flex: 1,
      valueGetter: ({ row }) => getLastRunTimestamp(jobRunHistory, row.jobId),
    },
    {
      field: 'editJob',
      headerName: 'Edit',
      headerAlign: 'center',
      flex: 0.5,
      renderCell: ({ row }) => (
        <IconButton
          className="mx-auto"
          onClick={() => updateJobBeingEdited(row)}
        >
          <Edit />
        </IconButton>
      ),
    },
    {
      field: 'deleteJob',
      headerName: 'Delete',
      headerAlign: 'center',
      flex: 0.5,
      renderCell: ({ row }) => (
        <IconButton
          className="mx-auto"
          onClick={() => updateJobBeingDeleted(row)}
        >
          <Delete />
        </IconButton>
      ),
    },
    {
      field: 'manualSync',
      headerName: 'Sync',
      headerAlign: 'center',
      flex: 0.5,
      renderCell: ({ row }) => ManualSyncButton(row.jobId),
    },
    {
      headerName: 'Group',
      field: 'group',
      width: 150,
      renderCell: ({ row }) => (
        <StandaloneGroupSelector
          tableId={row.jobId}
          refreshTable={updateGroupInfo.refreshTable}
          groupMappings={updateGroupInfo.groupMappings}
          groups={updateGroupInfo.groups}
          currentGroupId={updateGroupInfo.currentGroupId}
        />
      ),
    },
  ];

  return [...coreBQToGlideColumns, ...interactiveColumns];
};

// Params for BQ to Glide Datagrid jobs
interface BQToGlideDataGridParams {
  rows: BQToGlideJob[],
  updateGroupInfo: UpdateGroupInfo,
  updateJobBeingEdited: (job: BQToGlideJob | null) => void
  updateJobBeingDeleted: (job: BQToGlideJob | null) => void
  jobRunHistory: JobRunHistory[]
}

/**
 * Datagrid for BQ to Glide Jobs.
 * @param rows bq to glide jobs
 * @param updateGroupInfo info for updating groups
 * @param updateJobBeingEdited function to update the job being edited
 * @param updateJobBeingDeleted function to update the job being deleted
 * @param jobRunHistory all job history
 * @constructor
 */
export const BQToGlideDataGrid = function ({
  rows,
  updateGroupInfo,
  updateJobBeingEdited,
  updateJobBeingDeleted,
  jobRunHistory,
}: BQToGlideDataGridParams) {
  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <DataGridPro
        rows={rows}
        columns={getColumns(updateGroupInfo, updateJobBeingEdited, updateJobBeingDeleted, jobRunHistory)}
        getRowId={({ jobId }: BQToGlideJob) => jobId}
        disableRowSelectionOnClick
      />
    </Box>
  );
};
