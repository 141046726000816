import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { AxiosResponse } from 'axios';
import { CreateNewFolderOutlined, Sync } from '@mui/icons-material';
import { getEntrataTables } from '../../../api/endpoints-api/EndpointsAPI';
import { EntrataEditScheduleModal } from './EntrataEditScheduleModal';
import { EntrataTableData } from './entrata-sync-types';
import { useAlert } from '../../../api/alert-api/AlertAPI';
import { EntrataDeleteConfirmModal } from './EntrataDeleteConfirmModal';
import { EntrataTablesDataGrid } from './EntrataTablesDataGrid';
import { EntrataSyncParams } from './entrata-sync-params';
import { ActionsSpeedDial } from '../common/ActionsSpeedDial';
import { JobGroupsDataGrid } from '../common/JobGroupsDataGrid';

/**
 * Component which allows user to view and manage all Entrata sync jobs.
 * @constructor
 */
export const EntrataSync = function ({
  groups, groupMappings, loadingGroups, openCreateGroupModal, refreshTable, toggleRefresh, updateGroupToDelete,
}: EntrataSyncParams) {
  // sync jobs that populate DataGrid.  each row represents one table being synced from Entrata.
  const [tables, setTables] = useState<EntrataTableData[]>([]);
  const [loadingTableData, setLoadingTableData] = useState<boolean>(true);
  // the current table whose custom schedule is being edited
  const [tableIdBeingEdited, setTableIdBeingEdited] = useState<string>('');
  // current table whose custom schedule is being deleted
  const [tableScheduleBeingDeleted, setTableScheduleBeingDeleted] = useState<string>('');

  const alert = useAlert();

  const closeEditingModal = () => setTableIdBeingEdited('');
  const closeDeletionModal = () => setTableScheduleBeingDeleted('');

  /**
   * Retrieves all relevant data about Entrata sync jobs to populate the DataGrid.
   */
  useEffect(() => {
    // we want to refresh the table whenever the edit modal is being closed, but not when its being opened
    if (tableIdBeingEdited) return;
    setLoadingTableData(true);
    getEntrataTables()
      .then(({ data }: AxiosResponse<EntrataTableData[]>) => {
        const sortedTables = data.sort();
        setTables(sortedTables);
      })
      .catch(() => alert?.alert('Failed to retrieve table data', 'error'))
      .finally(() => setLoadingTableData(false));
  }, [toggleRefresh, tableIdBeingEdited]);

  // Given the table ID being edited, retrieve the relevant table/sync job metadata.
  const tableBeingEdited = tables.find((table) => table.table === tableIdBeingEdited);

  // MUI speed dial actions
  const speedDialActions = [
    { icon: <Sync />, name: 'Refresh table', action: refreshTable },
    { icon: <CreateNewFolderOutlined />, name: 'Create new group', action: openCreateGroupModal },
  ];

  return (
    <>
      {/* Modal to edit Entrata sync job custom schedule */}
      {tableBeingEdited
          && (
          <EntrataEditScheduleModal
            table={tableBeingEdited}
            closeModal={closeEditingModal}
          />
          )}
      {/* Confirmation Modal to delete Entrata sync job custom schedule */}
      {tableScheduleBeingDeleted
          && (
          <EntrataDeleteConfirmModal
            tableName={tableScheduleBeingDeleted}
            closeModal={closeDeletionModal}
            refreshTable={refreshTable}
          />
          )}
      {/* Sync jobs/tables datagrid */}
      <Box sx={{ width: '100%' }} className="h-[90%]">
        <JobGroupsDataGrid
          detailContent={({ row }) => EntrataTablesDataGrid({
            group: row,
            groups,
            groupMappings,
            setTableIdBeingEdited,
            setTableScheduleBeingDeleted,
            refreshTable,
          })}
          jobs={tables}
          getJobId={({ table }) => table}
          groupMappings={groupMappings}
          updateGroupToDelete={updateGroupToDelete}
          groups={groups}
          refreshTable={refreshTable}
          loading={loadingTableData || loadingGroups}
        />
      </Box>
      {/* Refreshes the data in the datagrid */}
      <div className="flex flex-col justify-center h-[10%] text-blue-500">
        <ActionsSpeedDial actions={speedDialActions} />
      </div>
    </>
  );
};
