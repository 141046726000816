import React, { useState } from 'react';
import {
  Button, Checkbox,
  Dialog,
  FormControlLabel,
  CircularProgress, IconButton, Box,
} from '@mui/material';
import { Clear } from '@mui/icons-material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { DeleteJobModalParams } from '../landing-page-params';
import { alertError } from '../../../api/error-api/ErrorAPI';
import { useAlert } from '../../../api/alert-api/AlertAPI';
import { updateTableGroup } from '../common/helpers';

export const DeleteJobModal = function ({
  deleteJob,
  closeModal,
  jobBeingDeleted,
  columns,
  getRowId,
  allowDeleteTable,
  groupMappings,
}: DeleteJobModalParams) {
  // whether or not the delete operation should also delete the related table from BigQuery
  const [deleteTableFromBigQuery, setDeleteTableFromBigQuery] = useState<boolean>(false);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);

  const alert = useAlert();

  /**
   * Deletes a sync job.
   */
  const deleteSyncJob = () => {
    setLoadingSubmit(true);
    deleteJob(deleteTableFromBigQuery)
      .then(async () => {
        // delete the job group mapping (if it has one)
        await updateTableGroup('', getRowId(jobBeingDeleted), groupMappings);
        closeModal();
        alert?.alert('Deleted job successfully.', 'success');
      })
      .catch((err) => { throw alertError(alert, 'Failed to delete job', err); })
      .finally(() => setLoadingSubmit(false));
  };

  return (
    <Dialog
      open
      onClose={closeModal}
      maxWidth="xl"
    >
      <div className="relative z-40 w-full bg-white rounded-lg py-4 flex flex-col
        items-center justify-center"
      >
        {/* Close modal button */}
        <IconButton className="absolute right-4 top-4" onClick={closeModal}>
          <Clear />
        </IconButton>
        {/* Content container */}
        <div className="mx-auto p-4 w-[90rem] h-[50vh] flex flex-col pt-4 font-inter items-center">
          <div className="text-2xl mb-12 font-semibold">Are you sure you want to delete this job?</div>
          <div className="w-4/5 flex flex-row justify-center">
            <Box sx={{ height: '100%', width: '100%' }}>
              <DataGridPro
                rows={[jobBeingDeleted]}
                columns={columns}
                getRowId={getRowId}
                disableRowSelectionOnClick
              />
            </Box>
          </div>
          {/* Checkbox - whether user wants to also delete table from BigQuery */}
          {allowDeleteTable && (
          <FormControlLabel
            className="mt-4"
            control={(
              <Checkbox
                checked={deleteTableFromBigQuery}
                onChange={({ target }) => setDeleteTableFromBigQuery(target.checked)}
              />
              )}
            label="Delete associated table from BigQuery?"
          />
          )}
          {/* For some jobs, their destination table cannot be deleted (e.g. Glide tables). So we display a notice. */}
          {!allowDeleteTable && (
          <div className="mt-4 text-red-500">Unable to automatically delete table(s) associated with this job.</div>
          )}
          {/* Delete button */}
          <Button
            className="w-44 bg-red-500 text-white hover:bg-red-400 bg:text-white my-auto h-12"
            onClick={deleteSyncJob}
            disabled={loadingSubmit}
          >
            {/*  Show loading icon while delete is being processed */}
            {loadingSubmit ? <CircularProgress size="2rem" className="text-white" /> : 'Delete'}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
