import { GridColDef } from '@mui/x-data-grid-pro';
import { deleteDoc, doc, setDoc } from 'firebase/firestore';
import { v4 as uuid } from 'uuid';
import cronstrue from 'cronstrue';
import {
  groupMappingsPath, TableGroupDoc, TableGroupMapping, TablesGroup,
} from '../landing-page-types';
import { TableGroupActions, UNSORTED_GROUP_ID } from './TableGroupActions';
import { UpdateGroupNameInput } from './UpdateGroupNameInput';
import { db } from '../../../../firebase-config';

/**
 * Generates grouped rows for parent DataGrids.  These rows can be expanded to show their children jobs/tables.
 * @param groupMappings mappings of tables to groups
 * @param groups groups data
 * @param tables all tables/jobs
 * @param getTableId function to get what will be used as the ID of the table/job for mapping purposes
 */
export const getGroupRows = <T>(
  groupMappings: TableGroupMapping[],
  groups: TableGroupDoc[],
  tables: T[],
  getTableId: (table: T) => string,
) => {
  // gets IDs for every table which is mapped to a group
  const mappedTables = groupMappings.map(({ tableId }) => tableId);
  // gets IDs for every table which is not mapped to a group
  const unmappedTables = tables.filter((table) => !mappedTables.includes(getTableId(table)));
  // create a default group for all unmapped tables
  const unsorted = {
    groupName: 'Unsorted',
    groupId: UNSORTED_GROUP_ID,
    tables: unmappedTables,
  };
    // for every group, coalesce all tables/jobs which are mapped to that group and return the result
  const mappedGroups = groups.map((group) => {
    const mappings = groupMappings.filter(({ groupId }) => groupId === group.id);
    const tableIds = mappings.map(({ tableId }) => tableId);
    const tablesInThisGroup = tables.filter((table) => tableIds.includes(getTableId(table)));
    return {
      tables: tablesInThisGroup,
      groupName: group.name,
      groupId: group.id,
    };
  });
    // only include the "unsorted" group if there are any unsorted tables
  return (unsorted.tables.length ? [...mappedGroups, unsorted] : mappedGroups) as TablesGroup<T>[];
};

/**
 * Get columns for groups datagrid.
 * @param updateGroupToDelete function to control the confirmation modal when deleting a group
 * @param groups relevant groups
 * @param refreshTable refreshes datagrid
 */
export const getGroupColumns = <T>(
  updateGroupToDelete: (group: TablesGroup) => void,
  groups: TableGroupDoc[],
  refreshTable: () => void,
): GridColDef<TablesGroup<T>>[] => [
    {
      headerName: 'Group',
      field: 'group',
      valueGetter: ({ row }) => row.groupName,
      flex: 1,
      renderCell: ({ row }) => UpdateGroupNameInput({ group: row, groups, refreshTable }),
    }, {
      headerName: 'Number of Tables',
      field: 'tableCount',
      align: 'right',
      valueGetter: ({ row }) => row.tables.length,
      width: 150,
    }, {
      headerName: 'Group Actions',
      field: 'actions',
      renderCell: ({ row }) => TableGroupActions({ group: row, updateGroupToDelete }),
      width: 150,
    }];

/**
 * Update a table's group by deleting any existing group membership and creating a new membership (if needed).
 * @param newGroup the new group to move the table to
 * @param tableId the id of the table being moved
 * @param groupMappings mappings of tables in groups
 */
export const updateTableGroup = async (newGroup: string, tableId: string, groupMappings: TableGroupMapping[]) => {
  // if the table currently belongs to a group, remove it from that group by deleting the relevant mapping doc
  const relevantMapping = groupMappings.find((mapping) => mapping.tableId === tableId);
  if (relevantMapping) await deleteDoc(doc(db, groupMappingsPath, relevantMapping.id));
  // if the user selected the "None" option, then we want to just remove them from their group and not add them to any new one
  if (!newGroup) return;
  // add the user to new group by creating new mapping doc
  const docId = uuid();
  const data: TableGroupMapping = { tableId, groupId: newGroup, id: docId };
  await setDoc(doc(db, groupMappingsPath, docId), data);
};

/**
 * Convert a cron expression to readable text.
 * @param refreshCron cron expression
 */
export const getSyncSchedule = (refreshCron: string) => (refreshCron ? cronstrue.toString(refreshCron) : 'Manual');

/**
 * Generates url to BQ table given table identifiers.
 * @param project project of table
 * @param dataset dataset containing table
 * @param table table id
 */
export const generateBigQueryURL = (
  project: string,
  dataset: string,
  table: string,
) => `https://console.cloud.google.com/bigquery?p=${project}&d=${dataset}&t=${table}&page=table`;
