import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import React from 'react';

// Represents an action of a Speed Dial component
interface DialAction {
  icon: JSX.Element,
  name: string,
  action: () => void
}

/**
 * Speed Dial MUI component that pops up with an actions bar when hovered over.
 * @param actions actions for speed dial to show onHover
 * @constructor
 */
export const ActionsSpeedDial = function ({ actions }:{ actions: DialAction[] }) {
  return (
    <SpeedDial
      ariaLabel="Actions"
      icon={<SpeedDialIcon />}
      direction="left"
      FabProps={{ sx: { backgroundColor: 'rgb(59 130 246) !important' } }}
    >
      {actions.map(({ name, icon, action }) => (
        <SpeedDialAction
          key={name}
          icon={icon}
          tooltipTitle={name}
          onClick={action}
        />
      ))}
    </SpeedDial>
  );
};
